import { Flex } from '@chakra-ui/react'
import moment from 'moment-timezone'

import { EventDetails } from '../../EventDetails'
import { useSchedulerContext } from '..'

export const EventData = () => {
	const {
		styles,
		profileName,
		profilePic,
		title,
		description,
		duration,
		timezone,
		locale,
		selectedDate,
		setTimezone,
		previousSlot,
		handleKeepPreviousSlot,
	} = useSchedulerContext()

	const formattedSelectedDate = moment(selectedDate)
		.tz(timezone)
		.locale(locale)
		.format('MMM D, YYYY')

	const formattedPreviousSlot = previousSlot
		? moment(previousSlot)
				.tz(timezone)
				.locale(locale)
				.format('MMM D, YYYY | hh:mm A')
		: null

	return (
		<Flex
			flexDir="column"
			backdropFilter="blur(4x)"
			p="20px"
			borderTopRadius="8px"
			bg={styles.bg_secondary}
		>
			<EventDetails
				profilePic={profilePic}
				profileName={profileName}
				styles={styles}
				title={title}
				description={description}
				duration={duration}
				timezone={timezone}
				locale={locale}
				selectedDate={formattedSelectedDate}
				setTimezone={setTimezone}
				previousSlot={formattedPreviousSlot}
				handleKeepPreviousSlot={handleKeepPreviousSlot}
			/>
		</Flex>
	)
}
