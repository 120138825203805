import { Text, VStack } from '@chakra-ui/react'
import Color from 'color'
import DOMPurify from 'isomorphic-dompurify'
import { useEffect, useRef, useState } from 'react'

import { StylesType } from '../../styles'
import { CALENDER_QUERIES } from '../Calendar/styles/responsive'

type Props = {
	title?: string | null
	description?: string | null
	styles: StylesType
}

export const EventContent = ({ title, description, styles }: Props) => {
	if (!title && !description) return null

	return (
		<VStack alignItems="flex-start" spacing="8px" mt="8px">
			<Text
				fontSize="20px"
				fontWeight={600}
				lineHeight="20px"
				sx={{
					[CALENDER_QUERIES.xs]: {
						fontSize: '16px',
					},
				}}
			>
				{title}
			</Text>
			<EventDescription description={description} styles={styles} />
		</VStack>
	)
}

const EventDescription = ({
	description,
	styles,
}: {
	description?: string | null
	styles: StylesType
}) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const [isOverflowing, setIsOverflowing] = useState(false)
	const textRef = useRef<HTMLParagraphElement>(null)

	const descriptionBaseColor = Color(styles.text_primary)

	useEffect(() => {
		const checkOverflow = () => {
			const element = textRef.current
			if (element) {
				// Compare scroll height with line height * 3
				const lineHeight = parseInt(getComputedStyle(element).lineHeight)
				const maxHeight = lineHeight * 3

				setIsOverflowing(element.scrollHeight > maxHeight)
			}
		}

		checkOverflow()
		// Recheck on window resize
		window.addEventListener('resize', checkOverflow)
		return () => window.removeEventListener('resize', checkOverflow)
	}, [description])

	if (!description || description.length === 0 || description === '<p></p>')
		return null

	return (
		<VStack alignItems="flex-start" spacing="4px">
			<Text
				ref={textRef}
				suppressHydrationWarning
				w="full"
				className="chronos_event_description"
				fontWeight={400}
				color={descriptionBaseColor.fade(0.4).hexa()}
				fontSize="14px"
				lineHeight="22px"
				sx={{
					[CALENDER_QUERIES.xs]: {
						fontSize: '13px',
					},
					display: '-webkit-box',
					WebkitLineClamp: isExpanded ? 'unset' : 3,
					WebkitBoxOrient: 'vertical',
					overflow: 'hidden',
				}}
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(description, {
						ADD_ATTR: ['target'],
					}),
				}}
			/>

			{isOverflowing && (
				<Text
					as="span"
					fontSize="13px"
					color={descriptionBaseColor.fade(0.5).hexa()}
					onClick={() => setIsExpanded(!isExpanded)}
					textDecoration="underline"
					textUnderlineOffset="3px"
					_hover={{
						cursor: 'pointer',
						color: descriptionBaseColor.fade(0.3).hexa(),
					}}
					sx={{
						[CALENDER_QUERIES.xs]: {
							fontSize: '12px',
						},
					}}
				>
					{isExpanded ? 'Read less' : 'Read more'}
				</Text>
			)}
		</VStack>
	)
}
