import { Avatar, Text, VStack } from '@chakra-ui/react'
import Color from 'color'

import { StylesType } from '../../styles'

type Props = {
	profilePic?: string | null
	profileName?: string | null
	styles: StylesType
}

export const EventHost = ({ profilePic, profileName, styles }: Props) => {
	if (!profilePic && !profileName) return null

	const baseColor = Color(styles.text_primary)

	return (
		<VStack alignItems="flex-start" spacing="8px">
			{profilePic && <Avatar src={profilePic} w="40px" h="40px" />}
			<Text
				fontSize="14px"
				fontWeight={500}
				color={baseColor.fade(0.45).hexa()}
			>
				{profileName}
			</Text>
		</VStack>
	)
}
