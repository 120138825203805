import { Center, HStack, Text } from '@chakra-ui/react'

import { GetIcon, Icons } from '../../../Icons'
import { useSchedulerContext } from '..'
import {
	createColorFromPrimaryText,
	getSecondaryButtonHoverBorderColor,
} from '../utils/colors'

export const CalendarHeader = () => {
	const { calendarState, timezone, styles, handleNextMonth } =
		useSchedulerContext()

	const date = calendarState.visibleRange.start.toDate(timezone)

	const month = date?.toLocaleString('default', { month: 'short' })
	const year = date?.getFullYear()

	const baseColor = createColorFromPrimaryText(styles.text_primary)

	const hoverBorderColor = getSecondaryButtonHoverBorderColor(
		styles.border_primary,
	)

	return (
		<HStack justifyContent="space-between">
			<HStack spacing={1}>
				<Text fontSize="16px" fontWeight={600} color={styles.text_primary}>
					{month}
				</Text>
				<Text
					fontSize="16px"
					fontWeight={500}
					color={baseColor.fade(0.55).hexa()}
				>
					{year}
				</Text>
			</HStack>
			<HStack spacing={2}>
				<Center
					as="button"
					w="32px"
					h="32px"
					rounded="full"
					border="1px solid"
					borderColor={styles.border_primary}
					onClick={() => calendarState.focusPreviousPage()}
					_hover={{
						borderColor: hoverBorderColor,
					}}
				>
					<GetIcon icon={Icons.chevron_left} />
				</Center>
				<Center
					as="button"
					w="32px"
					h="32px"
					rounded="full"
					border="1px solid"
					borderColor={styles.border_primary}
					onClick={handleNextMonth}
					_hover={{
						borderColor: hoverBorderColor,
					}}
				>
					<GetIcon icon={Icons.chevron_right} />
				</Center>
			</HStack>
		</HStack>
	)
}
