import { parseDate, today } from '@internationalized/date'

export const getDefaultDate = (availableTimes: string[], timezone: string) => {
	const now = new Date()

	for (const timeStr of availableTimes) {
		const date = new Date(timeStr)
		if (date >= now) {
			return parseDate(date.toISOString().split('T')[0])
		}
	}
	return today(timezone)
}
