import {
	TIME_ZONES_ENGLISH,
	TIME_ZONES_GERMAN,
	TIME_ZONES_SPANISH,
} from 'shared-utils'

import { TimezoneOptionSuffix } from './TimezoneOptionSuffix'

const format = 'h:mma'

export const getTimezoneOptions = (locale?: string) => {
	const TIMEZONES_WITH_LOCALE =
		locale === 'de'
			? TIME_ZONES_GERMAN
			: locale === 'es'
				? TIME_ZONES_SPANISH
				: TIME_ZONES_ENGLISH

	const OPTIONS = TIMEZONES_WITH_LOCALE.map((group) => ({
		...group,
		options: group.options.map((option) => ({
			...option,
			suffix: <TimezoneOptionSuffix timezone={option.value} format={format} />,
		})),
	}))

	return OPTIONS
}
