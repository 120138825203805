import { Flex } from '@chakra-ui/react'
import { matchPath } from 'react-router-dom'
import { Icons } from 'ui'

import { useAppSelector } from '@/app/hooks'
import { useMemberCanReadGettingStarted } from '@/app/roles/api/selectors'
import { selectIssHomeSidebarExpanded } from '@/app/user/slice/selectors'
import { useIsWfNotifications } from '@/common/hooks/feature-flags/useIsWfNotifications'
import { usePreviewMode } from '@/common/hooks/usePreviewMode'
import { WithNotifications } from '@/modules/notifications/components/WithNotifications'

import { SidebarHeader } from '../components/SidebarHeader'
import { SidebarNavItem } from '../components/SidebarNavItem'
import { SidebarProfile } from '../components/SidebarProfile'
import { useSidebarOptions } from '../hooks/useSidebarOptions'
import { SidebarLayout } from '../layouts/SidebarLayout'

export const HomeSidebarPartial = () => {
	const isExpanded = useAppSelector(selectIssHomeSidebarExpanded)
	const options = useSidebarOptions()
	const isPreview = usePreviewMode()
	const isWfNotificationsEnabled = useIsWfNotifications()
	const hasGettingStartedAccess = useMemberCanReadGettingStarted()

	if (isPreview) {
		return null
	}

	return (
		<SidebarLayout>
			<Flex direction="column" w="100%" alignItems="center" px={2}>
				<SidebarHeader isExpanded={isExpanded} />
				<Flex w="100%" direction="column" gap={1} mt={4}>
					{isWfNotificationsEnabled && (
						<WithNotifications>
							{({ isOpen, setIsOpen, unreadCount }) => (
								<SidebarNavItem
									title="Notifications"
									isActive={isOpen}
									isExpanded={isExpanded}
									navTo="/notifications"
									icon={Icons.bell}
									badge={unreadCount}
									onClick={() => {
										setIsOpen(!isOpen)
									}}
								/>
							)}
						</WithNotifications>
					)}
					{options.map((option) => {
						return (
							<SidebarNavItem
								key={option.title}
								isExpanded={isExpanded}
								{...option}
							/>
						)
					})}
				</Flex>
			</Flex>

			<Flex direction="column" gap={4} pb={3} px={2}>
				<Flex w="full" direction="column" gap={1}>
					<SidebarNavItem
						title="Docs & Help Center"
						isExpanded={isExpanded}
						navTo="https://intercom.help/defaulthq/en/"
						isNewTab
						icon={Icons.books}
					/>
					{hasGettingStartedAccess && (
						<SidebarNavItem
							title="Getting Started"
							isExpanded={isExpanded}
							navTo="/guide"
							isActive={
								!!matchPath(
									{ path: 'guide', end: false, caseSensitive: false },
									location.pathname,
								)
							}
							icon={Icons.guide}
						/>
					)}
					<SidebarNavItem
						title="Profile & Settings"
						isExpanded={isExpanded}
						navTo="settings/user/profile"
						isActive={
							!!matchPath(
								{ path: 'settings', end: false, caseSensitive: false },
								location.pathname,
							)
						}
						icon={Icons.shine}
					/>
				</Flex>
				<SidebarProfile isExpanded={isExpanded} />
			</Flex>
		</SidebarLayout>
	)
}
