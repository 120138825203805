import { Flex } from '@chakra-ui/react'

import { StylesType } from '../../styles'
import { BookingLinkSelectedDateBanner } from './BookingLinkSelectedDateBanner'
import { EventContent } from './EventContent'
import { EventDuration } from './EventDuration'
import { EventHost } from './EventHost'
import { SelectedDate } from './SelectedDate'
import { TimezonePicker } from './TimezonePicker'

type Props = {
	styles: StylesType
	profilePic?: string | null
	profileName?: string | null
	title?: string | null
	description?: string | null
	duration?: number | null
	timezone?: string
	selectedDate?: string
	locale: string
	setTimezone?: (timezone: string) => void
	isBookingLinkForm?: boolean
	previousSlot?: string | null
	handleKeepPreviousSlot?: () => void
}

export const EventDetails = ({
	styles,
	profilePic,
	profileName,
	title,
	description,
	duration,
	timezone,
	selectedDate,
	setTimezone,
	locale,
	isBookingLinkForm,
	previousSlot,
	handleKeepPreviousSlot,
}: Props) => {
	return (
		<Flex flexDir="column">
			<EventHost
				profilePic={profilePic}
				profileName={profileName}
				styles={styles}
			/>
			<EventContent title={title} description={description} styles={styles} />
			<Flex flexDir="column" gap="12px" mt="24px">
				<EventDuration duration={duration} styles={styles} />
				<TimezonePicker
					defaultStyles={styles}
					selectedTimezone={timezone}
					onChange={(tz) => setTimezone && setTimezone(tz?.value || '')}
					locale={locale}
					isDisabled={isBookingLinkForm}
				/>

				<SelectedDate
					styles={styles}
					selectedDate={selectedDate}
					previousSlot={previousSlot}
					handleKeepPreviousSlot={handleKeepPreviousSlot}
				/>

				{isBookingLinkForm && (
					<BookingLinkSelectedDateBanner
						styles={styles}
						selectedDate={selectedDate}
					/>
				)}
			</Flex>
		</Flex>
	)
}
