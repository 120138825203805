import { Flex, Text } from '@chakra-ui/react'
import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ResourceGroups } from '@/app/roles/types'
import { useIsMeetings2 } from '@/common/hooks/feature-flags/useIsMeeting2'
import { ErrorBoundaryComponent } from '@/components/ErrorBoundary'
import { LoadingPage } from '@/components/Loading'
import PermissionGuard from '@/routing/PermissionGuard'
import { TEXT_ENUM } from '@/styles/components/text'

import { DEEP_ROUTES, ROUTES } from '../../../routing/routes'
// import WorkflowsPage from '../../workflows-home/router/WorkflowsRouter'
import { HomeContentLayout } from '../layouts/HomeContentLayout'
import { HomeLayout } from '../layouts/HomeLayout'
import { HomeSidebarPartial } from '../partials/HomeSidebarPartial'
import { ProtectedPartial } from '../partials/ProtectedPartial'
const FormsPage = lazy(() => import('../../forms'))
const SettingsPage = lazy(() => import('../../settings/'))
const MeetingsRouter = lazy(() => import('../../meetings'))
const MeetingsHome = lazy(() => import('../../meetings-home'))
const SchedulerPage = lazy(() => import('../../scheduler'))
const GuidePage = lazy(() => import('../../guide'))
const PipelineRouter = lazy(() => import('../../pipeline'))
const WorkflowsRouter = lazy(
	() => import('../../workflows-home/router/WorkflowsRouter'),
)
const RoutingRouter = lazy(() => import('../../routing/router/RoutingRouter'))

export const HomePage = () => {
	return (
		<ProtectedPartial>
			<HomeLayout>
				<HomeSidebarPartial />
				<ErrorBoundaryComponent>
					<HomeContentLayout>
						<HomeRoutes />
					</HomeContentLayout>
				</ErrorBoundaryComponent>
			</HomeLayout>
		</ProtectedPartial>
	)
}

/**
 * There is some error related to circular dependency caused by
 * either Workflow or Form modules. That's why importing
 * this module not as Lazy.
 * Todo: Fix the circular dependency issue.
 */

const HomeRoutes = () => {
	const isMeetings2 = useIsMeetings2()

	return (
		<Suspense fallback={<LoadingPage />}>
			<Routes>
				{/* PERMISSION GUARD ROUTES */}
				<Route
					element={<PermissionGuard resourceGroup={ResourceGroups.FORMS} />}
				>
					<Route path={DEEP_ROUTES.FORMS} element={<FormsPage />} />
					<Route
						path={ROUTES.FORMS}
						element={<Navigate replace to="/forms/default" />}
					/>
				</Route>
				<Route
					element={<PermissionGuard resourceGroup={ResourceGroups.WORKFLOWS} />}
				>
					<Route
						path={DEEP_ROUTES.WORKFLOWS_HOME}
						element={<WorkflowsRouter />}
					/>
				</Route>
				<Route path={DEEP_ROUTES.SCHEDULER} element={<SchedulerPage />} />
				<Route
					element={<PermissionGuard resourceGroup={ResourceGroups.QUEUES} />}
				>
					<Route path={DEEP_ROUTES.ROUTING} element={<RoutingRouter />} />
				</Route>

				<Route
					element={
						<PermissionGuard resourceGroup={ResourceGroups.GETTING_STARTED} />
					}
				>
					<Route path={DEEP_ROUTES.guide} element={<GuidePage />} />
				</Route>

				<Route
					path={DEEP_ROUTES.MEETINGS}
					element={isMeetings2 ? <MeetingsHome /> : <MeetingsRouter />}
				/>
				<Route path={DEEP_ROUTES.PIPELINE} element={<PipelineRouter />} />
				<Route
					path={ROUTES.FORMS}
					element={<Navigate replace to="/forms/default" />}
				/>
				<Route path={DEEP_ROUTES.SETTINGS} element={<SettingsPage />} />

				<Route
					path="/maintenance"
					element={
						<Flex
							w="100%"
							alignItems="center"
							justifyContent="center"
							direction="column"
							gap={2}
							px={8}
						>
							<Text textAlign="center" variant={TEXT_ENUM.medium16}>
								Scheduled Maintenance Mode
							</Text>
							<Text textAlign="center" variant={TEXT_ENUM.regular14}>
								We are currently performing scheduled maintenance on the
								dashboard page you are trying to access. Published Default
								resources and deployments are not affected. Please check back
								soon.
							</Text>
						</Flex>
					}
				/>
				<Route
					path={ROUTES.ALL}
					element={
						<Navigate replace to="/pipeline/contacts/views/all_contacts" />
					}
				/>
			</Routes>

			<Routes>
				<Route
					path={ROUTES.INDEX}
					element={
						<Navigate replace to="/pipeline/contacts/views/all_contacts" />
					}
				/>
			</Routes>
		</Suspense>
	)
}
