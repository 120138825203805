import { components, DropdownIndicatorProps } from 'react-select'

import { GetIcon, Icons } from '../../../../../Icons'
import { StylesType } from '../../../../../styles'

interface Props extends DropdownIndicatorProps<any, boolean, any> {
	defaultStyles?: StylesType
}

export const DropdownIndicator = ({ defaultStyles, ...props }: Props) => {
	if (props.selectProps.isDisabled) return null

	return (
		<components.DropdownIndicator {...props}>
			<GetIcon
				icon={Icons.chevron_down}
				color={defaultStyles?.text_primary}
				transform={props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'}
			/>
		</components.DropdownIndicator>
	)
}
