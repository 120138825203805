import { HStack, Text } from '@chakra-ui/react'

import { useSchedulerContext } from '..'
import { createColorFromPrimaryText } from '../utils/colors'

export const TimeslotsHeader = () => {
	const { styles } = useSchedulerContext()

	const baseColor = createColorFromPrimaryText(styles.text_primary)

	return (
		<HStack w="full" justifyContent="space-between">
			<Text fontSize="14px" fontWeight={400} color={baseColor.fade(0.3).hexa()}>
				Select a time
			</Text>
		</HStack>
	)
}
