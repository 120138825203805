import { HStack, Text } from '@chakra-ui/react'
import Color from 'color'

import { GetIcon, Icons } from '../../Icons'
import { StylesType } from '../../styles'

type Props = {
	styles: StylesType
	duration?: number | null
}

export const EventDuration = ({ duration, styles }: Props) => {
	if (!duration) return null

	const minutes = duration / 60
	const textColor = Color(styles.text_primary).fade(0.5).hexa()

	return (
		<HStack gap="10px" color={textColor}>
			<GetIcon icon={Icons.clock2} boxSize="16px" color={textColor} />
			<Text fontSize="14px" fontWeight={400}>
				{minutes} mins
			</Text>
		</HStack>
	)
}
