const ROUTES = {
	ALL: '*',
	EMPTY: '',
	INDEX: '/',
	INITIAL: '/pipeline/contacts/views',
	LOGIN: '/login',
	LOGOUT: '/logout',
	ONBOARDING: '/onboarding',
	INTEGRATIONS: '/integrations',
	INTEGRATIONS_CUSTOMERIO: 'customerio',
	INTEGRATIONS_SEGMENT: 'segment',

	INTEGRATIONS_G_CALENDAR_CALLBACK: 'gcal/callback',

	INTEGRATIONS_SALESFORCE: 'salesforce',
	INTEGRATIONS_SALESFORCE_CALLBACK: 'sfdc/callback',

	INTEGRATIONS_SLACK: 'slack',
	INTEGRATIONS_SLACK_CALLBACK: 'slack/callback',

	INTEGRATIONS_HUBSPOT: 'hubspot',
	INTEGRATIONS_HUBSPOT_CALLBACK: 'hubspot/callback',

	INTEGRATIONS_MARKETO: 'marketo',
	INTEGRATIONS_MARKETO_CALLBACK: 'marketo/callback',

	INTEGRATIONS_OUTREACH: 'outreach',
	INTEGRATIONS_OUTREACH_CALLBACK: 'outreach/callback',

	INTEGRATIONS_SALESLOFT: 'salesloft',
	INTEGRATIONS_SALESLOFT_CALLBACK: 'salesloft/callback',

	INTEGRATIONS_HARMONIC: 'harmonic',

	INTEGRATIONS_APOLLO: 'apollo',

	INTEGRATIONS_ZOOM: 'zoom',
	INTEGRATIONS_ZAPIER: 'zapier',
	INTEGRATIONS_GTM: 'gtm',
	INTEGRATIONS_AMPLEMARKET: 'amplemarket',

	CHECKLIST: '/checklist',
	FORMS: '/forms',
	DEFAULT_FORMS: '/default',
	WEBFORMS: '/webforms',
	DEFAULT_FORM: '/default/:formId',
	WEBFORM: '/webforms/:formId',
	FORM: ':formId',
	FORMS_EDITOR: 'editor',
	FORMS_LOGIC: 'logic',
	FORMS_STYLE: 'style',
	FORMS_WORKFLOW: 'workflow',
	FORM_WORKFLOW_LOGS: 'workflow-logs',
	FORM_WORKFLOW_LOG_DETAILS: 'workflow-log/details/:submissionId',
	FORM_IMPORT_FIELDS: 'import-fields',
	FORMS_SHARING: 'sharing',
	FORMS_RESPONSES: 'responses',

	SETTINGS: '/settings',
	SETTINGS_PROFILE: 'profile',
	SETTINGS_BRAND: 'brand',
	SETTINGS_MEMBERS: 'members',
	SETTINGS_LEADS: 'leads',
	LEAD_OVERVIEW: 'overview',
	LEAD_RESPONSES: 'responses',
	LEAD_MEETINGS: 'meetings',
	LEAD_NOTES: 'notes',
	LEAD: ':leadId',

	SCHEDULER: '/scheduler',
	SCHEDULER_EVENTS: 'events/*',
	EVENT: 'events/:eventId',
	EVENT_CONFIGURATION: 'configuration',
	EVENT_TIME: 'time',
	EVENT_REMINDERS: 'reminders',
	QUEUE: 'queues/:queueId',
	QUEUE_MEMBERS: 'members',
	QUEUE_SETTINGS: 'settings',
	AVAILABILITY_CONFIGURATION: 'configuration',
	AVAILABILITY_CALENDAR: 'calendar',
	AVAILABILITY_LOCATION: 'location',
	SCHEDULER_QUEUES: 'queues',
	SCHEDULER_MEETINGS: 'meetings',
	SCHEDULER_STYLE: 'style',
	SCHEDULER_AVAILABILITY: 'settings',
	SCHEDULE: 'schedule/:scheduleId',
	SCHEDULE_LOGIC: 'logic',
	SCHEDULE_TIME: 'time',
	SCHEDULE_STYLE: 'style',

	COMPANIES: 'companies',

	ROUTING: '/routing',
	ROUTING_TERRITORIES: 'territories',
	TERRITORY: '/territories/:territoryId',
	ROUTING_QUEUES: 'queues',
}

const DEEP_ROUTES = {
	INDEX: `/*`,
	SETTINGS: `/settings/*`,
	PIPELINE: `/pipeline/*`,
	COMPANIES: '/companies/*',
	SEQUENCES: `/sequences/*`,
	INTEGRATIONS: `/integrations/*`,
	FORMS: `/forms/*`,
	WORKFLOWS_HOME: `/workflows/*`,
	SCHEDULER: `/scheduler/*`,
	MEETINGS: `/meetings/*`,
	ONBOARDING: `/onboarding/*`,
	CHECKLIST: `/checklist/*`,
	guide: `/guide/*`,
	ROUTING: `/routing/*`,
}

export { DEEP_ROUTES, ROUTES }
