import moment from 'moment-timezone'

export const partitionSlots = (slots: string[], timezone: string) => {
	const morningSlots = slots.filter(
		(s) => moment(s).tz(timezone).get('hours') < 12,
	)
	const afternoonSlots = slots.filter(
		(s) =>
			moment(s).tz(timezone).get('hours') >= 12 &&
			moment(s).tz(timezone).get('hours') < 18,
	)
	const eveningSlots = slots.filter(
		(s) => moment(s).tz(timezone).get('hours') >= 18,
	)
	return { morningSlots, afternoonSlots, eveningSlots }
}
