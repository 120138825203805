import { configureStore } from '@reduxjs/toolkit'

import { eventConfigReducer } from '@/modules/event-config/slice'
import { formsReducer } from '@/modules/forms/slice'
import { logicJumpsReducer } from '@/modules/forms/slice/logicJumpsSlice'
import { formsHomeReducer } from '@/modules/forms-home/slice'
import { loginReducer } from '@/modules/login/slice'
import { meetingDetailsReducer } from '@/modules/meeting-details/slice'
import { meetingsReducer } from '@/modules/meetings/slice'
import { meetingsHomeReducer } from '@/modules/meetings-home/slice'
import { queueLogsReducer } from '@/modules/queue-logs/slice'
import { queuesReducer } from '@/modules/queues/slice'
import { remindersReducer } from '@/modules/reminders/slice'
import { schedulerStyleReducer } from '@/modules/scheduler2-style/slice'
import { workspaceSettingsReducer } from '@/modules/settings/slice'
import { formsImportReducer } from '@/modules/webforms/slice'
import { workflowsReducer } from '@/modules/workflow/slice'
import { workflowLogsReducer } from '@/modules/workflow-logs/slice'
import { workflowsHomeReducer } from '@/modules/workflows-home/slice'

import { baseApi } from './api'
import { rolesApi } from './roles/api'
import { schedulerReducer } from './scheduler/slice'
import { userReducer } from './user/slice'

export const store = configureStore({
	reducer: {
		user: userReducer,
		forms: formsReducer,
		formsHome: formsHomeReducer,
		scheduler: schedulerReducer,
		meetings: meetingsReducer,
		workflowLogs: workflowLogsReducer,
		formsImport: formsImportReducer,
		workflowsHome: workflowsHomeReducer,
		workflows: workflowsReducer,
		queues: queuesReducer,
		queueLogs: queueLogsReducer,
		login: loginReducer,
		workspaceSettings: workspaceSettingsReducer,
		meetingDetails: meetingDetailsReducer,
		meetingsHome: meetingsHomeReducer,
		reminders: remindersReducer,
		logicJumps: logicJumpsReducer,
		eventConfig: eventConfigReducer,
		schedulerStyle: schedulerStyleReducer,

		// API
		[baseApi.reducerPath]: baseApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(baseApi.middleware),
	devTools: { trace: true },
})

store.dispatch(rolesApi.endpoints.getRoleForCurrentUser.initiate())

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
