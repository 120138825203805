import { Flex } from '@chakra-ui/react'

import { CalendarGrid } from './CalendarGrid'
import { CalendarHeader } from './CalendarHeader'

export const CalendarView = () => {
	return (
		<Flex p="20px" flexDir="column" gap={3}>
			<CalendarHeader />
			<CalendarGrid />
		</Flex>
	)
}
