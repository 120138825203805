export const TIME_ZONES_SPANISH = [
	{
		label: 'EE.UU./Canadá',
		options: [
			{
				label: 'Hora del Pacífico - EE.UU. & Canadá',
				value: 'America/Los_Angeles',
			},
			{
				label: 'Hora de la Montaña - EE.UU. & Canadá',
				value: 'America/Denver',
			},
			{
				label: 'Hora Central - EE.UU. & Canadá',
				value: 'America/Chicago',
			},
			{
				label: 'Hora del Este - EE.UU. & Canadá',
				value: 'America/New_York',
			},
			{
				label: 'Hora de Alaska',
				value: 'America/Anchorage',
			},
			{
				label: 'Hora de Arizona, Yukón',
				value: 'America/Phoenix',
			},
			{
				label: 'Hora de Terranova',
				value: 'America/St_Johns',
			},
			{
				label: 'Hora de Hawái',
				value: 'Pacific/Honolulu',
			},
		],
	},
	{
		label: 'América',
		options: [
			{
				label: 'América/Adak',
				value: 'America/Adak',
			},
			{
				label: 'Hora de Buenos Aires',
				value: 'America/Argentina/Buenos_Aires',
			},
			{
				label: 'Hora de Asunción',
				value: 'America/Asuncion',
			},
			{
				label: 'Hora de Bogotá, Jamaica, Lima',
				value: 'America/Bogota',
			},
			{
				label: 'América/Campo Grande',
				value: 'America/Campo_Grande',
			},
			{
				label: 'Hora de Caracas',
				value: 'America/Caracas',
			},
			{
				label: 'América/Godthab',
				value: 'America/Godthab',
			},
			{
				label: 'Hora del Atlántico',
				value: 'America/Halifax',
			},
			{
				label: 'Hora de Saskatchewan, Guatemala, Costa Rica',
				value: 'America/Regina',
			},
			{
				label: 'América/La Habana',
				value: 'America/Havana',
			},
			{
				label: 'América/Mazatlán',
				value: 'America/Mazatlan',
			},
			{
				label: 'Hora de Ciudad de México',
				value: 'America/Mexico_City',
			},
			{
				label: 'Hora de Montevideo',
				value: 'America/Montevideo',
			},
			{
				label: 'América/Miquelon',
				value: 'America/Miquelon',
			},
			{
				label: 'América/Noronha',
				value: 'America/Noronha',
			},
			{
				label: 'Hora de Santiago',
				value: 'America/Santiago',
			},
			{
				label: 'América/Santa Isabel',
				value: 'America/Santa_Isabel',
			},
			{
				label: 'Hora Estándar del Atlántico',
				value: 'America/Thule',
			},
			{
				label: 'Hora de Brasilia',
				value: 'America/Sao_Paulo',
			},
		],
	},
	{
		label: 'África',
		options: [
			{
				label: 'África/El Cairo',
				value: 'Africa/Cairo',
			},
			{
				label: 'Hora de África Central',
				value: 'Africa/Maputo',
			},
			{
				label: 'Hora de África Occidental',
				value: 'Africa/Lagos',
			},
			{
				label: 'África/Windhoek',
				value: 'Africa/Windhoek',
			},
		],
	},
	{
		label: 'ASIA',
		options: [
			{
				label: 'Hora de Jordania',
				value: 'Asia/Amman',
			},
			{
				label: 'Hora de Bagdad, África Oriental',
				value: 'Asia/Baghdad',
			},
			{
				label: 'Asia/Bakú',
				value: 'Asia/Baku',
			},
			{
				label: 'Hora de Líbano',
				value: 'Asia/Beirut',
			},
			{
				label: 'Hora de Siria',
				value: 'Asia/Damascus',
			},
			{
				label: 'Asia/Dhaka',
				value: 'Asia/Dhaka',
			},
			{
				label: 'Hora de Dubái',
				value: 'Asia/Dubai',
			},
			{
				label: 'Asia/Gaza',
				value: 'Asia/Gaza',
			},
			{
				label: 'Asia/Irkutsk',
				value: 'Asia/Irkutsk',
			},
			{
				label: 'Hora de Indochina',
				value: 'Asia/Bangkok',
			},
			{
				label: 'Hora de Israel',
				value: 'Asia/Jerusalem',
			},
			{
				label: 'Hora de Kabul',
				value: 'Asia/Kabul',
			},
			{
				label: 'Pacífico/Majuro',
				value: 'Pacific/Majuro',
			},
			{
				label: 'Hora de Pakistán, Maldivas',
				value: 'Asia/Karachi',
			},
			{
				label: 'Hora de Katmandú',
				value: 'Asia/Kathmandu',
			},
			{
				label: 'Hora de India, Sri Lanka',
				value: 'Asia/Calcutta',
			},
			{
				label: 'Hora de Krasnoyarsk',
				value: 'Asia/Krasnoyarsk',
			},
			{
				label: 'Asia/Omsk',
				value: 'Asia/Omsk',
			},
			{
				label: 'Asia/Rangún',
				value: 'Asia/Yangon',
			},
			{
				label: 'Hora de China, Singapur, Perth',
				value: 'Asia/Shanghai',
			},
			{
				label: 'Hora de Teherán',
				value: 'Asia/Tehran',
			},
			{
				label: 'Hora de Japón, Corea',
				value: 'Asia/Tokyo',
			},
			{
				label: 'Asia/Vladivostok',
				value: 'Asia/Vladivostok',
			},
			{
				label: 'Asia/Yakutsk',
				value: 'Asia/Yakutsk',
			},
			{
				label: 'Hora de Ekaterimburgo',
				value: 'Asia/Yekaterinburg',
			},
			{
				label: 'Asia/Ereván',
				value: 'Asia/Yerevan',
			},
		],
	},
	{
		label: 'Atlántico',
		options: [
			{
				label: 'Hora de Azores',
				value: 'Atlantic/Azores',
			},
			{
				label: 'Hora de Cabo Verde',
				value: 'Atlantic/Cape_Verde',
			},
		],
	},
	{
		label: 'Australia',
		options: [
			{
				label: 'Hora de Adelaida',
				value: 'Australia/Adelaide',
			},
			{
				label: 'Hora de Brisbane',
				value: 'Australia/Brisbane',
			},
			{
				label: 'Australia/Darwin',
				value: 'Australia/Darwin',
			},
			{
				label: 'Australia/Eucla',
				value: 'Australia/Eucla',
			},
			{
				label: 'Australia/Lord Howe',
				value: 'Australia/Lord_Howe',
			},
			{
				label: 'Australia/Perth',
				value: 'Australia/Perth',
			},
			{
				label: 'Hora de Sídney, Melbourne',
				value: 'Australia/Sydney',
			},
		],
	},
	{
		label: 'UTC',
		options: [
			{
				label: 'Hora UTC',
				value: 'Etc/UTC',
			},
		],
	},
	{
		label: 'Europa',
		options: [
			{
				label: 'Hora de Europa Central',
				value: 'Europe/Berlin',
			},
			{
				label: 'Hora de Europa del Este',
				value: 'Europe/Bucharest',
			},
			{
				label: 'Hora de Reino Unido, Irlanda, Lisboa',
				value: 'Europe/London',
			},
			{
				label: 'Hora de Minsk',
				value: 'Europe/Minsk',
			},
			{
				label: 'Hora de Moscú',
				value: 'Europe/Moscow',
			},
			{
				label: 'Hora de Turquía',
				value: 'Europe/Istanbul',
			},
		],
	},
	{
		label: 'Pacífico',
		options: [
			{
				label: 'Pacífico/Apia',
				value: 'Pacific/Apia',
			},
			{
				label: 'Hora de Auckland',
				value: 'Pacific/Auckland',
			},
			{
				label: 'Pacífico/Chatham',
				value: 'Pacific/Chatham',
			},
			{
				label: 'Pacífico/Pascua',
				value: 'Pacific/Easter',
			},
			{
				label: 'Pacífico/Fiyi',
				value: 'Pacific/Fiji',
			},
			{
				label: 'Pacífico/Gambier',
				value: 'Pacific/Gambier',
			},
			{
				label: 'Pacífico/Kiritimati',
				value: 'Pacific/Kiritimati',
			},
			{
				label: 'Pacífico/Majuro',
				value: 'Pacific/Majuro',
			},
			{
				label: 'Pacífico/Marquesas',
				value: 'Pacific/Marquesas',
			},
			{
				label: 'Pacífico/Norfolk',
				value: 'Pacific/Norfolk',
			},
			{
				label: 'Pacífico/Noumea',
				value: 'Pacific/Noumea',
			},
			{
				label: 'Pacífico/Pago Pago',
				value: 'Pacific/Pago_Pago',
			},
			{
				label: 'Pacífico/Pitcairn',
				value: 'Pacific/Pitcairn',
			},
			{
				label: 'Pacífico/Tarawa',
				value: 'Pacific/Tarawa',
			},
			{
				label: 'Pacífico/Tongatapu',
				value: 'Pacific/Tongatapu',
			},
		],
	},
]
