import { Icons } from '@ds/Icons'
import { matchPath } from 'react-router-dom'

import {
	useMemberCanReadWorkflows,
	useMemberCanWriteForms,
	useMemberCanWriteMeetingReminders,
	useMemberCanWriteQueues,
	useMemberCanWriteSchedulerMeetings,
	useMemberCanWriteSchedulerStyle,
} from '@/app/roles/api/selectors'
import { useTerritoriesSegmentsRouting } from '@/common/hooks/feature-flags/useTerritoriesSegmentsRouting'

export type SidebarSuboptionType = {
	title: string
	navTo: string
	isActive?: boolean
	hide?: boolean
}

export type SidebarOptionType = {
	title: string
	icon: Icons
	navTo: string
	suboptions?: SidebarSuboptionType[]
	hide?: boolean
	isActive?: boolean
	isNewTab?: boolean
}

export const useSidebarOptions = () => {
	const isTerritoriesSegmentsEnabled = useTerritoriesSegmentsRouting()
	const hasWorkflowsAccess = useMemberCanReadWorkflows()
	const hasFormsWriteAccess = useMemberCanWriteForms()
	const hasQueueWriteAccess = useMemberCanWriteQueues()
	const hasMeetingRemindersAccess = useMemberCanWriteMeetingReminders()
	const hasSchedulerStyleAccess = useMemberCanWriteSchedulerStyle()
	const hasSchedulerMeetingsAccess = useMemberCanWriteSchedulerMeetings()

	const getActive = (matchTo: string) => {
		return !!matchPath(
			{
				path: matchTo,
				caseSensitive: false,
				end: false,
			},
			location.pathname,
		)
	}

	const OPTIONS: SidebarOptionType[] = [
		{
			title: 'Contacts',
			icon: Icons.contact,
			navTo: `pipeline/contacts/views/all_contacts`,
			isActive: getActive('pipeline/contacts/views'),
		},
		{
			title: 'Companies',
			icon: Icons.company,
			navTo: `pipeline/companies/views/all_companies`,
			isActive: getActive('pipeline/companies/views'),
		},
		{
			title: 'Meetings',
			icon: Icons.meetings,
			navTo: '/meetings/personal',
			isActive: getActive('/meetings'),
			suboptions: hasSchedulerMeetingsAccess
				? [
						{
							title: 'My meetings',
							navTo: 'meetings/personal',
							isActive: getActive('/meetings/personal'),
						},
						{
							title: 'Team meetings',
							navTo: 'meetings/team',
							isActive: getActive('/meetings/team'),
						},
					]
				: undefined,
		},
		{
			title: 'Forms',
			icon: Icons.input,
			navTo: '/forms/default',
			isActive: getActive('/forms'),
			hide: !hasFormsWriteAccess,
			suboptions: [
				{
					title: 'Default forms',
					navTo: '/forms/default',
					isActive: getActive('/forms/default'),
				},
				{
					title: 'Webforms',
					navTo: 'forms/webforms',
					isActive: getActive('forms/webforms'),
				},
			],
		},
		{
			title: 'Scheduler',
			icon: Icons.calendar_empty,
			isActive: getActive('/scheduler'),
			navTo: '/scheduler/events/list/favorites',
			suboptions:
				hasMeetingRemindersAccess || hasSchedulerStyleAccess
					? [
							{
								title: 'Event types',
								navTo: `scheduler/events/list/favorites`,
								isActive:
									getActive('scheduler/events/list/:eventType') ||
									getActive('scheduler/event/:eventId'),
							},
							{
								navTo: 'scheduler/reminders',
								title: 'Meeting reminders',
								isActive:
									getActive('scheduler/reminders') ||
									getActive('scheduler/reminders/:reminderId'),
								hide: !hasMeetingRemindersAccess,
							},
							{
								title: 'Scheduler style',
								navTo: 'scheduler/style',
								isActive: getActive('scheduler/style'),
								hide: !hasSchedulerStyleAccess,
							},
						]
					: undefined,
		},
		{
			title: 'Routing',
			icon: Icons.routing,
			navTo: '/routing/queues',
			isActive: getActive('/routing'),
			hide: !hasQueueWriteAccess,
			suboptions: [
				...(isTerritoriesSegmentsEnabled
					? [
							{
								navTo: 'routing/territories',
								title: 'Territories & Segments',
								isActive: getActive('routing/territories'),
							},
						]
					: []),
				{
					navTo: 'routing/queues',
					title: 'Queues',
					isActive:
						getActive('routing/queues') || getActive('routing/queue/:queueId'),
				},
			],
		},
		{
			title: 'Workflows',
			icon: Icons.workflow,
			navTo: '/workflows',
			isActive: getActive('/workflows'),
			hide: !hasWorkflowsAccess,
		},
	]

	return OPTIONS.filter(({ hide }) => !hide)
}
