import Color from 'color'

export const createColorFromPrimaryText = (primaryColor: string) => {
	return Color(primaryColor)
}

export const createColorFromPrimaryBorder = (primaryBorderColor: string) => {
	return Color(primaryBorderColor)
}

export const getSecondaryButtonHoverBorderColor = (
	primaryBorderColor: string,
) => {
	const baseBorderColor = createColorFromPrimaryBorder(primaryBorderColor)

	return baseBorderColor.isDark()
		? baseBorderColor.lighten(0.8).hexa()
		: baseBorderColor.darken(0.3).hexa()
}
