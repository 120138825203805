import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	EventConferecingEnum,
	EventFutureAvailability,
	EventInviteesConfig,
	EventTypeEnum,
	SchedulerEventWithQueues,
	WebinarSettingsType,
} from '@/app/scheduler/api/events/type'
import { NotificationTemplateType } from '@/modules/notification-templates/types'
import { QueueTypeEnum } from '@/modules/queues/types'

export type PrimaryHostType = {
	hostId: string
	type: 'member' | 'queue'
}

export type AdditionalHostType = {
	hostId: string
	isOptional: boolean
}

export type HostType = {
	hostId: string // memberId or queueId
	type: 'member' | 'queue'
	is_primary_host: boolean
	is_optional: boolean
	queueMemberId?: number
	queueName?: string
}

interface EventConfigState {
	draftEvent: SchedulerEventWithQueues | null
	draftNotificationTemplate: NotificationTemplateType | null
	dbNotificationTemplate: NotificationTemplateType | null
	hosts: HostType[]
	dbHosts: HostType[]
}

export const initialState: EventConfigState = {
	draftEvent: null,
	draftNotificationTemplate: null,
	dbNotificationTemplate: null,
	hosts: [],
	dbHosts: [],
}

export const eventConfigSlice = createSlice({
	name: 'event-config',
	initialState,
	reducers: {
		setDraftEvent: (
			state,
			action: PayloadAction<SchedulerEventWithQueues | null>,
		) => {
			state.draftEvent = action.payload

			if (action.payload?.type == EventTypeEnum.group) {
				const eventQueues = action.payload?.queues || []

				const groupQueueHosts: HostType[] =
					eventQueues
						.find((q) => q.queue.type === QueueTypeEnum.group)
						?.queue?.queues_members.map((qm) => ({
							hostId: qm.member_id,
							queueMemberId: qm.id,
							type: 'member',
							is_primary_host: action.payload?.host_id === qm.member_id,
							is_optional: qm.is_optional,
						})) || []

				const nonGroupQueues: HostType[] = eventQueues
					.filter((q) => q.queue.type !== QueueTypeEnum.group)
					.map((q) => {
						return {
							hostId: q.queue.id.toString(),
							type: 'queue',
							is_primary_host:
								action.payload?.host_id?.toString() === q.queue.id.toString(),
							is_optional: q.is_optional,
							queueName: q.queue.name,
						}
					})

				state.hosts = [...groupQueueHosts, ...nonGroupQueues]
				state.dbHosts = [...groupQueueHosts, ...nonGroupQueues]
			}
		},
		setDraftEventTitle: (state, action: PayloadAction<string>) => {
			state.draftEvent!.title = action.payload
		},
		setDraftEventDescription: (state, action: PayloadAction<string>) => {
			state.draftEvent!.description = action.payload
		},
		setDraftEventDuration: (state, action: PayloadAction<number>) => {
			state.draftEvent!.duration = action.payload
		},
		setDraftEventConferencing: (
			state,
			action: PayloadAction<EventConferecingEnum | null>,
		) => {
			state.draftEvent!.conferencing = action.payload
		},
		setDraftPreMeetingBuffer: (state, action: PayloadAction<number>) => {
			state.draftEvent!.pre_meeting_buffer = action.payload
		},
		setDraftPostMeetingBuffer: (state, action: PayloadAction<number>) => {
			state.draftEvent!.post_meeting_buffer = action.payload
		},
		setDraftNoticePeriod: (state, action: PayloadAction<number>) => {
			state.draftEvent!.notice_period = action.payload
		},
		setDraftTimeslotIncrement: (state, action: PayloadAction<number>) => {
			state.draftEvent!.timeslot_increment = action.payload
		},
		setDraftCalendarEventTitle: (state, action: PayloadAction<string>) => {
			state.draftEvent!.calendar_title = action.payload
		},
		setDraftCalendarEventDescription: (
			state,
			action: PayloadAction<string>,
		) => {
			state.draftEvent!.calendar_description = action.payload
		},
		setDraftConferencingNativeDescription: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.draftEvent!.conferencing_native_description = action.payload
		},
		setDbNotificationTemplate: (
			state,
			action: PayloadAction<NotificationTemplateType | null>,
		) => {
			state.dbNotificationTemplate = action.payload
		},
		setDraftNotificationTemplate: (
			state,
			action: PayloadAction<NotificationTemplateType | null>,
		) => {
			state.draftNotificationTemplate = action.payload
		},
		setHostSubject: (state, action: PayloadAction<string>) => {
			state.draftNotificationTemplate!.host_subject = action.payload
		},
		setGuestSubject: (state, action: PayloadAction<string>) => {
			state.draftNotificationTemplate!.guest_subject = action.payload
		},
		setHostBody: (state, action: PayloadAction<string>) => {
			state.draftNotificationTemplate!.host_body = action.payload
		},
		setGuestBody: (state, action: PayloadAction<string>) => {
			state.draftNotificationTemplate!.guest_body = action.payload
		},
		setHostPlainText: (state, action: PayloadAction<boolean>) => {
			state.draftNotificationTemplate!.is_host_plain_text = action.payload
		},
		setGuestPlainText: (state, action: PayloadAction<boolean>) => {
			state.draftNotificationTemplate!.is_guest_plain_text = action.payload
		},
		setDraftFutureAvailability: (
			state,
			action: PayloadAction<EventFutureAvailability | null>,
		) => {
			state.draftEvent!.future_availability = action.payload
		},
		setDraftDisableInvite: (state, action: PayloadAction<boolean | null>) => {
			state.draftEvent!.disable_invite = action.payload
		},
		setDraftInviteesConfig: (
			state,
			action: PayloadAction<EventInviteesConfig | null>,
		) => {
			state.draftEvent!.invitees_config = action.payload
		},
		setDraftIsWebinarEnabled: (
			state,
			action: PayloadAction<boolean | null>,
		) => {
			state.draftEvent!.is_webinar_enabled = action.payload
		},
		setDraftWebinarSettings: (
			state,
			action: PayloadAction<WebinarSettingsType | null>,
		) => {
			state.draftEvent!.webinar_settings = action.payload
		},
		setPrimaryHostId: (state, action: PayloadAction<string | null>) => {
			state.draftEvent!.host_id = action.payload
		},
		setHosts: (state, action: PayloadAction<HostType[]>) => {
			state.hosts = action.payload
		},
	},
})

export const eventConfigActions = eventConfigSlice.actions
export const eventConfigReducer = eventConfigSlice.reducer
