import { HStack } from '@chakra-ui/react'
import Color from 'color'
import { Props } from 'react-select'

import { GetIcon, Icons } from '../../../Icons'
import { StylesType } from '../../../styles'
import { getTimezoneOptions } from './getTimezoneOptions'
import { Select } from './Select'
import { TimezoneGroupOptionType, TimezoneSelectOptionType } from './type'

interface CustomProps
	extends Props<TimezoneSelectOptionType, false, TimezoneGroupOptionType> {
	width?: string
	minHeight?: string
	matchWidth?: boolean
	selectedTimezone?: string
	defaultStyles: StylesType
	locale?: string
}

export const TimezonePicker = ({
	selectedTimezone,
	locale,
	...props
}: CustomProps) => {
	const formattedOptions: TimezoneGroupOptionType[] = getTimezoneOptions(locale)

	const iconColor = Color(props.defaultStyles.text_primary).fade(0.5).hexa()

	return (
		<HStack gap="7px">
			<GetIcon icon={Icons.map_market} boxSize="16px" color={iconColor} />
			<Select
				value={
					selectedTimezone
						? { label: selectedTimezone, value: selectedTimezone }
						: null
				}
				placeholder="Select timezone"
				options={formattedOptions}
				isSearchable
				{...props}
			/>
		</HStack>
	)
}
