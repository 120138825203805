import { Flex, HStack, Text } from '@chakra-ui/react'

import { GetIcon, Icons } from '../../../Icons'
import { useSchedulerContext } from '..'
import { createColorFromPrimaryText } from '../utils/colors'

export const TimeSlotSectionLoading = () => {
	return (
		<Flex flexDir="column" w="full" h="full" gap="16px">
			<TimeSlotColumnLoading noOfSlots={4} name="Morning" icon={Icons.sunset} />
			<TimeSlotColumnLoading noOfSlots={3} name="Daytime" icon={Icons.sun} />
			<TimeSlotColumnLoading noOfSlots={4} name="Evening" icon={Icons.moon} />
		</Flex>
	)
}

const TimeSlotColumnLoading = ({
	noOfSlots,
	name,
	icon,
}: {
	noOfSlots: number
	name: string
	icon: Icons
}) => {
	const { styles } = useSchedulerContext()

	const labelColor = createColorFromPrimaryText(styles.text_primary)
		.fade(0.4)
		.hexa()

	return (
		<Flex direction="column" gap="12px" w="full">
			<HStack alignItems="center" gap="8px">
				<GetIcon icon={icon} color={labelColor} />
				<Text fontSize="14px" fontWeight={400} color={labelColor}>
					{name}
				</Text>
			</HStack>

			<Flex w="full" flexDir="column" gap="8px">
				{Array.from({ length: noOfSlots }).map((_, index) => (
					<Slot key={index} />
				))}
			</Flex>
		</Flex>
	)
}

const Slot = () => {
	const { styles } = useSchedulerContext()

	return (
		<Flex
			rounded={6}
			w="full"
			h="36px"
			minH="36px"
			bg={`linear-gradient(90deg, ${styles.loading_gradient_1} 0%, ${styles.loading_gradient_2} 52%, ${styles.loading_gradient_3} 100%);`}
		/>
	)
}
