import { chakra } from '@chakra-ui/react'

import { ReactComponent as FullLogo } from '../assets/logo/full-logo.svg'
import { ReactComponent as HubspotLogo } from '../assets/logo/hubspot.svg'
import { ReactComponent as SalesforceLogo } from '../assets/logo/salesforce.svg'
import { ReactComponent as ZoomLogo } from '../assets/logo/zoom.svg'
import { ReactComponent as NewFeatureTag } from '../assets/svg-components/new-feature-tag.svg'

const FullDefaultLogo = chakra(FullLogo)

export { FullDefaultLogo, HubspotLogo, NewFeatureTag, SalesforceLogo, ZoomLogo }
