import './scheduler.css'

import { Button, Flex } from '@chakra-ui/react'
import moment from 'moment'

import { GetIcon, Icons } from '../../Icons'
import { CALENDER_QUERIES } from '../Calendar/styles/responsive'
import { CalendarView } from './CalendarView'
import { EventData } from './EventData'
import { useSchedulerContext } from './index'
import { Timeslots } from './Timeslots'

export const SchedulerContent = () => {
	const { styles, clickedDate, selectedDate } = useSchedulerContext()

	const isSelectedDate = Boolean(clickedDate && selectedDate)

	return (
		<Flex
			flexDir="column"
			w="full"
			h="full"
			justifyContent="center"
			alignItems="center"
			gap="16px"
			maxW="720px"
			color={styles.text_primary}
			fontFamily={styles.font_primary}
			sx={{
				[CALENDER_QUERIES.sm]: {
					w: isSelectedDate ? '95%' : 'full',
				},
			}}
		>
			<GoToCalendarButton isSelectedDate={isSelectedDate} />
			<Flex
				border="1px solid"
				borderRadius="8px"
				color={styles.text_primary}
				borderColor={styles.border_primary}
				p="16px"
				gap="16px"
				sx={{
					[CALENDER_QUERIES.sm]: {
						border: isSelectedDate ? '1px solid' : 'none',
						borderColor: styles.border_primary,
						w: isSelectedDate ? 'full' : 'fit-content',
					},
				}}
			>
				<Flex
					h="full"
					maxW="432px"
					flexDir="column"
					border="1px solid"
					borderColor={styles.border_primary}
					borderRadius="8px"
					sx={{
						[CALENDER_QUERIES.sm]: {
							display: isSelectedDate ? 'none' : 'flex',
						},
					}}
				>
					<EventData />
					<CalendarView />
				</Flex>
				<Flex
					flexDir="column"
					sx={{
						[CALENDER_QUERIES.sm]: {
							display: isSelectedDate ? 'flex' : 'none',
							w: 'full',
							maxH: '65vh',
						},
					}}
				>
					<Timeslots isSelectedDate={isSelectedDate} />
				</Flex>
			</Flex>
		</Flex>
	)
}

const GoToCalendarButton = ({
	isSelectedDate,
}: {
	isSelectedDate: boolean
}) => {
	const { styles, selectedDate, locale, setClickedDate } = useSchedulerContext()

	if (!isSelectedDate) return null

	const label = moment(selectedDate).locale(locale).format('MMM D, YYYY')

	return (
		<Button
			bg={styles.bg_secondary}
			border="1px solid"
			fontSize="14px"
			fontWeight={500}
			borderColor={styles.border_primary}
			borderRadius="8px"
			rightIcon={<GetIcon icon={Icons.calendar_empty} />}
			leftIcon={<GetIcon icon={Icons.arrow_left} />}
			display="none"
			color={styles.text_primary}
			w="full"
			flexShrink={0}
			sx={{
				[CALENDER_QUERIES.sm]: {
					display: 'flex',
				},
			}}
			_hover={{
				bg: styles.bg_secondary,
			}}
			onClick={() => setClickedDate(null)}
		>
			{label}
		</Button>
	)
}
