export enum ResourceGroups {
	WORKSPACE_SETTINGS = 'WORKSPACE_SETTINGS',
	SETTINGS_CONFIGURATION = 'SETTINGS_CONFIGURATION',
	USER_SETTINGS = 'USER_SETTINGS',
	INTEGRATIONS = 'INTEGRATIONS',
	EVENT_TYPES = 'EVENT_TYPES',
	FORMS = 'FORMS',
	LEADS = 'LEADS',
	MEETING_REMINDERS = 'MEETING_REMINDERS',
	QUEUES = 'QUEUES',
	SCHEDULER_MEETINGS = 'SCHEDULER_MEETINGS',
	SCHEDULER_STYLING = 'SCHEDULER_STYLING',
	GETTING_STARTED = 'GETTING_STARTED',
	WORKFLOWS = 'WORKFLOWS',
}

export type PermissionAbilities = {
	read: boolean
	write: boolean
}

export type Permissions = {
	[key in ResourceGroups]: PermissionAbilities
}

export interface Role {
	role_id: string
	team_id: string
	title: string
	permissions: Permissions
}
