import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultFormStyle, ThemeStyle } from 'shared-utils'
interface SchedulerStyleState {
	scheduler_version: string
	draftSchedulerStyles: ThemeStyle
}

export const initialState: SchedulerStyleState = {
	scheduler_version: 'v1',
	draftSchedulerStyles: defaultFormStyle,
}

export const schedulerStyleSlice = createSlice({
	name: 'scheduler-style',
	initialState,
	reducers: {
		setSchedulerVersion: (state, action: PayloadAction<string>) => {
			state.scheduler_version = action.payload
		},
		setDraftSchedulerStyles: (state, action: PayloadAction<ThemeStyle>) => {
			state.draftSchedulerStyles = action.payload
		},
	},
})

export const schedulerStyleActions = schedulerStyleSlice.actions
export const schedulerStyleReducer = schedulerStyleSlice.reducer
