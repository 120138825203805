import Color from 'color'
import { StylesConfig } from 'react-select'

import { StylesType } from '../../../../../styles'

type Args = {
	customStyles?: StylesConfig<any, any, any> | undefined
	defaultStyles?: StylesType
}

export const mergeSelectStyles = ({ customStyles, defaultStyles }: Args) => {
	const {
		container,
		singleValue,
		placeholder,
		menu,
		menuList,
		option,
		multiValue,
		multiValueRemove,
		noOptionsMessage,
		input,
		...restStyles
	} = customStyles || {}

	const textColor = Color(defaultStyles?.text_primary).fade(0.5).hexa()

	const baseStyles: StylesConfig<any, boolean, any> = {
		container: (baseStyles, state) => ({
			...baseStyles,
			border: 'none',
			padding: '0px',
			...container?.(baseStyles, state),
		}),
		control: (baseStyles) => {
			return {
				...baseStyles,
				width: '100%',
				border: 'none',
				minHeight: '0px',
				background: 'transparent',
				boxShadow: 'none',
				cursor: 'pointer',
				padding: '0px',
			}
		},
		valueContainer: (baseStyles) => {
			return {
				...baseStyles,
				padding: '0px',
			}
		},
		singleValue: (baseStyles, state) => ({
			...baseStyles,
			color: textColor,
			fontSize: '14px',
			padding: '0px',
			fontWeight: 400,
			...singleValue?.(baseStyles, state),
		}),
		placeholder: (baseStyles, state) => ({
			...baseStyles,
			color: defaultStyles?.placeholder_text_primary,
			fontSize: '14px',
			padding: '0px',
			...placeholder?.(baseStyles, state),
		}),
		menu: (baseStyles, state) => {
			return {
				...baseStyles,
				backgroundColor: defaultStyles?.popover_bg_primary,
				width: '280px',
				minWidth: 'fit-content',
				borderColor: 'transparent',
				zIndex: 999999,
				marginTop: '0px',
				borderRadius: '8px',
				...menu?.(baseStyles, state),
			}
		},
		menuList: (baseStyles, state) => {
			return {
				...baseStyles,
				padding: '4px',
				'::-webkit-scrollbar': {
					display: 'none',
					width: 0,
					height: 0,
					'-webkit-appearance': 'none',
				},
				'::-ms-overflow-style': 'none',
				'scrollbar-width': 'none',
				...menuList?.(baseStyles, state),
			}
		},
		option: (baseStyles, state) => {
			return {
				...baseStyles,
				color: defaultStyles?.text_primary,
				fontSize: '14px',
				borderRadius: '6px',
				fontWeight: 500,
				cursor: 'pointer',
				margin: '4px 0',
				backgroundColor:
					state.isSelected || state.isFocused
						? defaultStyles?.popover_active_bg_primary
						: 'transparent',
				'&:hover': {
					backgroundColor: defaultStyles?.popover_hover_bg_primary,
				},
				...option?.(baseStyles, state),
			}
		},
		multiValue: (baseStyles, state) => ({
			...baseStyles,
			borderRadius: '6px',
			fontSize: '14px',
			color: defaultStyles?.text_primary,
			fontWeight: 500,
			backgroundColor: defaultStyles?.bg_secondary,
			...multiValue?.(baseStyles, state),
		}),
		indicatorsContainer: (baseStyles) => {
			return {
				...baseStyles,
				padding: '0px',
			}
		},
		multiValueRemove: (baseStyles, state) => ({
			...baseStyles,
			'&:hover': {
				backgroundColor: 'transparent',
			},
			...multiValueRemove?.(baseStyles, state),
		}),
		noOptionsMessage: (baseStyles, state) => ({
			...baseStyles,
			color: defaultStyles?.text_tertiary,
			fontSize: '14px',
			...noOptionsMessage?.(baseStyles, state),
		}),
		input: (baseStyles, state) => ({
			...baseStyles,
			color: defaultStyles?.text_primary,
			fontSize: '14px',
			padding: '0px',
			fontWeight: 500,
			width: '100px',
			...input?.(baseStyles, state),
		}),
	}

	return {
		...baseStyles,
		...restStyles,
	}
}
