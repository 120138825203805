/**
 * It checks only date not time
 * e.g 31-12-2021 === 31-12-2021
 */
export const isDateNotAvailable = (
	availableTimes: string[],
	date: Date,
	timezone: string,
) => {
	const formatter = new Intl.DateTimeFormat('en-US', {
		timeZone: timezone,
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	})

	const parts = formatter.formatToParts(date)
	const month = parts.find((part) => part.type === 'month')?.value
	const day = parts.find((part) => part.type === 'day')?.value
	const year = parts.find((part) => part.type === 'year')?.value

	if (!month || !day || !year) {
		// Fall back to date not available (True)
		return true
	}

	// Get just the date portion of ISO strings (YYYY-MM-DD)
	const dateToFind = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
	return !availableTimes.some((timeStr) => timeStr.startsWith(dateToFind))
}
