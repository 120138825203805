import { Text } from '@chakra-ui/react'
import { components, SingleValueProps } from 'react-select'

export const SingleValue = (props: SingleValueProps<any, boolean, any>) => {
	const showSearch =
		props.selectProps.isSearchable && props.selectProps.menuIsOpen

	if (props.selectProps.isLoading) return null

	return (
		<components.SingleValue {...props}>
			<Text>{showSearch ? 'Search...' : props.children}</Text>
		</components.SingleValue>
	)
}
