import { baseApi } from '@/app/api'
import { responseTransformer } from '@/app/api/helper/response'

import { Role } from '../types'

export enum RolesEndPoints {
	/** Query */
	getRoleById = 'getRoleById',
	getRoleForCurrentUser = 'getRoleForCurrentUser',
}

const rolesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		[RolesEndPoints.getRoleById]: builder.query<Role, string>({
			query: (roleId) => ({
				url: `/roles/${roleId}`,
				responseHandler: responseTransformer,
			}),
		}),
		[RolesEndPoints.getRoleForCurrentUser]: builder.query<Role, void>({
			query: () => ({
				url: `/roles/self`,
				responseHandler: responseTransformer,
			}),
		}),
	}),
})

export const { useGetRoleByIdQuery, useGetRoleForCurrentUserQuery } = rolesApi

export { rolesApi }
