import './styles/index.css'
import './wdyr'

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { FlatfileProvider } from '@flatfile/react'
import * as Sentry from '@sentry/react'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom'

import { VITE_FLATFILE_PUBLISHABLE_KEY } from './app/config'
import { store } from './app/store'
import { ErrorBoundaryComponent } from './components/ErrorBoundary'
import LoadingPage from './components/Loading/LoadingPage'
import { App } from './modules/app'
import { KnockProvider } from './modules/notifications/providers/KnockProvider'
import { LaunchDarklyProvider } from './Providers/LaunchDarklyProvider'
import { LocalizationProvider } from './Providers/LocalizationProvider'
import defaultTheme from './styles/themeConfig'

const domNode = document.getElementById('root') as HTMLElement

Sentry.init({
	dsn: 'https://01fdbe850e734be397997a62dde5585e@o1180762.ingest.us.sentry.io/6295413',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ['https://forms.default.com/*'],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
})

const RootApp = () => {
	return (
		<Suspense fallback={<LoadingPage />}>
			<LaunchDarklyProvider>
				<LocalizationProvider>
					<App />
				</LocalizationProvider>
			</LaunchDarklyProvider>
		</Suspense>
	)
}

const router = createBrowserRouter(
	createRoutesFromElements(<Route path="*" element={<RootApp />} />),
)

const root = createRoot(domNode)

root.render(
	<StrictMode>
		<ChakraProvider theme={defaultTheme}>
			<ColorModeScript
				initialColorMode={defaultTheme.config.initialColorMode}
			/>
			<Provider store={store}>
				<KnockProvider>
					<ErrorBoundaryComponent>
						<FlatfileProvider publishableKey={VITE_FLATFILE_PUBLISHABLE_KEY}>
							<RouterProvider router={router} />
						</FlatfileProvider>
					</ErrorBoundaryComponent>
				</KnockProvider>
			</Provider>
		</ChakraProvider>
	</StrictMode>,
)
