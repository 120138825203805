import { HStack, Text } from '@chakra-ui/react'
import { components, OptionProps } from 'react-select'

export const Option = (props: OptionProps<any, boolean, any>) => {
	return (
		<components.Option {...props}>
			<HStack justifyContent="space-between" gap={4}>
				<HStack gap={1}>
					{props.data.prefix}
					<Text>{props.children}</Text>
				</HStack>
				{props.data.suffix}
			</HStack>
		</components.Option>
	)
}
