import { getManipulatedColors } from '../../color'
import { defaultFormStyle } from '../../default-theme'

export type BackgroundInputType = 'color' | 'image' | 'gradient'

export enum PageLayout {
	no_media = 'no_media',
	third_media = 'third_media',
	half_media = 'half_media',
}

export type Alignment = 'start' | 'center' | 'end'

export interface MediaBlock {
	title: string | null
	images: string[] | null
	gallery: boolean
	autoScroll: boolean
	overlay: number
	blur: number
	backgroundColor: string
	enabled: boolean
}

export type LogoPosition = 'media' | 'form'

export type BorderRadius = '0' | 'md' | 'full'

export type FieldVariant = 'outline' | 'filled' | 'flushed'

export type FieldStyle = {
	borderColor: string
	type: string
	width: string
	background: string
}

export interface LogoType {
	image?: string | null
	side?: LogoPosition
	enabled?: boolean
	url?: string | null
}

export interface Favicon {
	image?: string | null
	enabled?: boolean
}

export interface AdvancedTextBlockItem {
	property?: string
	value: string
	enabled?: boolean
	media?: boolean
}

export interface AdvancedTextBlock {
	alignment?: Alignment
	images?: string[]
	lines: AdvancedTextBlockItem[]
	enabled?: boolean
}

export interface BaseMediaItem {
	elementType: 'media' | 'advancedText'
	elementConfig: {
		[key: string]: any
	}
	elementStyle: {
		[key: string]: any
	}
}

export interface ShortHandCSS {
	top: string
	right: string
	bottom: string
	left: string
}

export interface FieldDimensions {
	width: string
	height: string
	unit: 'px' | '%'
}

export interface ThemeStyle {
	favicon?: Favicon
	primaryFont?: string
	customPrimaryFontUrl?: string | null
	secondaryFont?: string
	customSecondaryFontUrl?: string | null
	logo?: LogoType
	logoAreaBackground?: string
	logoRedirectLink?: string | null

	title?: string
	subtitle?: string
	titleSpacing?: string
	endTitle?: string
	endSubtitle?: string
	phoneCountryCode?: string | null
	phoneCountry?: string | null
	requireWorkEmail?: boolean | null

	layout?: PageLayout
	alignment?: Alignment
	primaryMediaAreaColor?: string
	primaryFormAreaColor?: string
	secondaryMediaAreaColor?: string
	secondaryFormAreaColor?: string

	// media area bg props
	mediaAreaBackgroundType?: BackgroundInputType
	mediaAreaBackgroundColor?: string
	mediaAreaBackgroundImage?: string
	mediaAreaBackgroundImageBlur?: number
	mediaAreaBackgroundImageOverlay?: number
	// form area bg props
	formAreaBackgroundType?: BackgroundInputType
	formAreaBackgroundColor?: string
	formAreaBackgroundImage?: string
	formAreaBackgroundImageOverlay?: number
	formAreaBackgroundImageBlur?: number

	// form area nesting
	nestFormArea?: boolean
	nestedFormAreaPadding?: ShortHandCSS | null
	nestedFormAreaBorderRadius?: ShortHandCSS | null

	//global area bg props
	globalBackgroundType?: BackgroundInputType
	globalBackgroundColor?: string
	globalBackgroundImage?: string
	globalBackgroundImageOverlay?: number
	globalBackgroundImageBlur?: number

	// buttons
	buttonAlignment?: Alignment | null
	buttonWidth?: string | null
	buttonIcon?: boolean
	buttonIconUrl?: string | null
	buttonFontSize?: string | null
	buttonFontWeight?: string | null
	borderRadius?: string
	primaryButtonsBackground?: string
	primaryButtonsBorderColor?: string
	primaryButtonsColor?: string
	hasReCaptcha?: boolean
	secondaryButtonsBackground?: string
	secondaryButtonsBorderColor?: string
	secondaryButtonsColor?: string

	// field styles
	variant?: FieldVariant | null
	fieldVerticalSpacing?: string
	fieldBorderRadius?: string
	fieldBackground?: string
	defaultState?: FieldStyle | null
	hoverState?: FieldStyle | null
	selectedState?: FieldStyle | null
	activeState?: FieldStyle | null
	fieldDimensions?: FieldDimensions
}

export interface SupabaseTheme {
	id: number
	team_id: number
	name: string
	style: ThemeStyle | null
	revision_number: number
	updated_at: string
	created_at: string
	deleted_at: string | null
}

export interface ExtendedStylesType {
	// fonts
	font_primary: string
	font_secondary: string

	// utils
	is_translucent: boolean

	// backgrounds
	bg_image: string
	bg_overlay: number
	bg_blur: number
	bg_primary: string
	bg_secondary: string

	// text colors
	text_primary: string
	text_secondary: string
	text_tertiary: string
	placeholder_text_primary: string

	// borders
	border_primary: string
	border_secondary: string

	// buttons
	btn_radius: string
	btn_bg_primary: string
	btn_bg_secondary: string
	btn_color_primary: string
	btn_color_secondary: string
	btn_border_primary: string
	btn_bg_tertiary: string

	// hover
	hover_border_primary: string
	hover_bg_primary: string

	// focus
	focus_border_primary: string

	// active
	active_border_primary: string
	active_bg_primary: string
	active_text_primary: string

	// popover
	popover_bg_primary: string
	popover_color_primary: string
	popover_border_primary: string
	popover_color_tertiary: string

	popover_hover_bg_primary: string
	popover_focus_bg_primary: string
	popover_active_bg_primary: string

	// loading
	loading_gradient_1: string
	loading_gradient_2: string
	loading_gradient_3: string
}

export const getExtendedStyles = (th: ThemeStyle): ExtendedStylesType => {
	const theme = { ...defaultFormStyle, ...th } as Required<ThemeStyle>
	const {
		bg_secondary,
		border_primary,
		hover_border_primary,
		focus_border_primary,
		active_border_primary,
		placeholder_text_primary,
		popover_bg_primary,
		text_tertiary,
		popover_border_primary,
		popover_color_tertiary,
		popover_color_primary,
		btn_bg_tertiary,
		popover_hover_bg_primary,
		hover_bg_primary,
		loading_gradient_1,
		loading_gradient_2,
		loading_gradient_3,
	} = getManipulatedColors({
		bg_primary: theme.formAreaBackgroundColor,
		text_primary: theme.primaryFormAreaColor,
		btn_bg_primary: theme.primaryButtonsBackground,
		btn_color_primary: theme.primaryButtonsColor,
		is_translucent: theme?.formAreaBackgroundType === 'image',
	})

	return {
		// fonts
		font_primary: theme.primaryFont,
		font_secondary: theme.secondaryFont,

		// utils
		is_translucent: theme?.formAreaBackgroundType === 'image',

		// backgrounds
		bg_image: theme.formAreaBackgroundImage,
		bg_blur: theme.formAreaBackgroundImageBlur,
		bg_overlay: theme.formAreaBackgroundImageOverlay,
		bg_primary: theme.formAreaBackgroundColor,
		bg_secondary: bg_secondary,
		// text colors
		text_primary: theme.primaryFormAreaColor,
		text_secondary: theme.secondaryFormAreaColor,
		text_tertiary: text_tertiary,
		placeholder_text_primary: placeholder_text_primary,

		// borders
		border_primary: border_primary,
		border_secondary: '',

		// buttons
		btn_radius: theme.borderRadius,
		btn_bg_primary: theme.primaryButtonsBackground,
		btn_bg_secondary: theme.secondaryButtonsBackground,
		btn_color_primary: theme.primaryButtonsColor,
		btn_color_secondary: theme.secondaryButtonsColor,
		btn_border_primary: theme.primaryButtonsBorderColor,
		btn_bg_tertiary: btn_bg_tertiary,

		// hover
		hover_border_primary: hover_border_primary,
		hover_bg_primary: hover_bg_primary,

		// focus
		focus_border_primary: focus_border_primary,

		// active
		active_border_primary: active_border_primary,
		active_text_primary: theme.primaryButtonsColor,
		active_bg_primary: theme.primaryButtonsBackground,

		// popover
		popover_bg_primary: popover_bg_primary,
		popover_color_primary: popover_color_primary,
		popover_border_primary: popover_border_primary,
		popover_color_tertiary: popover_color_tertiary,
		popover_hover_bg_primary: popover_hover_bg_primary,
		popover_active_bg_primary: popover_hover_bg_primary,
		popover_focus_bg_primary: popover_hover_bg_primary,

		//
		loading_gradient_1: loading_gradient_1,
		loading_gradient_2: loading_gradient_2,
		loading_gradient_3: loading_gradient_3,
	}
}
