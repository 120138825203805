import { useMemo } from 'react'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { usePermissions } from '@/app/roles/api/selectors'
import { ResourceGroups } from '@/app/roles/types'

type Props = {
	resourceGroup: ResourceGroups | ResourceGroups[]
	action?: 'read' | 'write'
	redirectTo?: string
}

const PermissionGuard = React.memo(
	({ resourceGroup, action = 'read', redirectTo = '/' }: Props) => {
		const permissions = usePermissions()

		const hasAccess = useMemo(() => {
			const hasPermission = (group: ResourceGroups) => {
				return !!permissions?.[group]?.[action]
			}

			if (Array.isArray(resourceGroup)) {
				return resourceGroup.every((group) => hasPermission(group))
			}
			return hasPermission(resourceGroup)
		}, [permissions, resourceGroup, action])

		if (!hasAccess) {
			return <Navigate to={redirectTo} replace />
		}

		return <Outlet />
	},
)

export default PermissionGuard
