import { Center, GridItem, Text } from '@chakra-ui/react'
import {
	CalendarDate,
	isSameDay,
	isSameMonth,
	parseAbsolute,
} from '@internationalized/date'
import { useMemo } from 'react'

import { CALENDER_QUERIES } from '../../Calendar/styles/responsive'
import { useSchedulerContext } from '..'
import { CalendarCellLoading } from '../Loadings/CalendarCellLoading'
import { createColorFromPrimaryText } from '../utils/colors'

interface Props {
	date: CalendarDate
}

export const CalendarCell = ({ date }: Props) => {
	const {
		calendarState,
		timezone,
		styles,
		isAvailabilityLoading,
		setClickedDate,
	} = useSchedulerContext()

	const baseColor = createColorFromPrimaryText(styles.text_primary)

	const isCellUnavailable = calendarState.isCellUnavailable(date)
	const disabled =
		isCellUnavailable || !isSameMonth(calendarState.visibleRange.start, date)

	const isSelected =
		calendarState.value?.toDate(timezone)?.toDateString() ===
		date.toDate(timezone)?.toDateString()

	if (isAvailabilityLoading) return <CalendarCellLoading />

	const handleDayCellClick = () => {
		if (disabled) return
		calendarState.setValue(date)
		setClickedDate(date)
	}

	return (
		<GridItem
			as="button"
			h="45px"
			w="45px"
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			fontSize="14px"
			bg={isSelected ? styles.btn_bg_primary : baseColor.fade(0.88).hexa()}
			color={isSelected ? styles.btn_color_primary : styles.text_primary}
			rounded="full"
			onClick={handleDayCellClick}
			disabled={disabled}
			_disabled={{
				bg: 'transparent',
				color: baseColor.fade(0.65).hexa(),
			}}
			cursor={disabled ? 'not-allowed' : 'pointer'}
			sx={{
				[CALENDER_QUERIES.xs]: {
					w: '36px',
					h: '36px',
				},
			}}
		>
			{!disabled && <CalendarCellDot date={date} />}
			<Text fontSize="14px" fontWeight={500}>
				{date.day}
			</Text>
		</GridItem>
	)
}

export default CalendarCell

const CalendarCellDot = ({ date }: { date: CalendarDate }) => {
	const { availableTimes, timezone } = useSchedulerContext()

	const allSlots = useMemo(
		() =>
			availableTimes.filter((ad) =>
				isSameDay(parseAbsolute(ad, timezone), date),
			),
		[availableTimes, timezone, date],
	)

	if (allSlots.length === 0) return null
	const bg = allSlots.length < 5 ? '#FFB324' : '#0ED06F'
	return <Center w={1} h={1} rounded="full" bg={bg}></Center>
}
