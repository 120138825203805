import { Center, Text } from '@chakra-ui/react'

import { StylesType } from '../../styles'

export const BookingLinkSelectedDateBanner = ({
	styles,
	selectedDate,
}: {
	styles: StylesType
	selectedDate?: string
}) => {
	if (!selectedDate) return

	return (
		<Center
			mt="64px"
			bg={styles.bg_primary}
			h="36px"
			borderRadius="8px"
			border="1px solid"
			borderColor={styles.border_primary}
			px="12px"
		>
			<Text fontSize="14px" fontWeight={500} color={styles.text_primary}>
				Your booking: {selectedDate}
			</Text>
		</Center>
	)
}
