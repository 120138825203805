import { Button, HStack, Text } from '@chakra-ui/react'
import Color from 'color'

import { GetIcon, Icons } from '../../Icons'
import { StylesType } from '../../styles'

type Props = {
	styles: StylesType
	selectedDate?: string
	previousSlot?: string | null
	handleKeepPreviousSlot?: () => void
}

export const SelectedDate = ({
	styles,
	selectedDate,
	previousSlot,
	handleKeepPreviousSlot,
}: Props) => {
	const color = Color(styles.text_primary).fade(0.5).hexa()

	return (
		<HStack gap="16px">
			<HStack gap="8px" color={color}>
				<GetIcon icon={Icons.calendar_empty} boxSize="16px" color={color} />
				<Text
					fontSize="14px"
					fontWeight={500}
					textDecor={previousSlot ? 'line-through' : 'none'}
				>
					{previousSlot || selectedDate}
				</Text>
			</HStack>

			{handleKeepPreviousSlot && previousSlot && (
				<Button
					variant="link"
					onClick={handleKeepPreviousSlot}
					textDecor="underline"
					textUnderlineOffset="3px"
					color={color}
					fontSize="14px"
					fontWeight={500}
				>
					Keep existing slot
				</Button>
			)}
		</HStack>
	)
}
