'use client'
import 'moment/locale/de.js'
import 'moment/locale/es.js'

import { Flex } from '@chakra-ui/react'
import { CalendarDate, createCalendar, today } from '@internationalized/date'
import React, { useEffect, useMemo, useState } from 'react'
import { CalendarState, useCalendarState } from 'react-stately'
import { WebinarSlot } from 'shared-utils'

import { getDefaultDate } from '../../common/utils/getDefaultDate'
import { isDateNotAvailable } from '../../common/utils/isDateNotAvailable'
import { StylesType } from '../../styles'
import { getMillisecondsOfDays } from '../Calendar/context'
import { CALENDAR_RESPONSIVE_CONTAINER } from '../Calendar/styles/responsive'
import { DateRange } from '../Calendar/types'
import { SchedulerContent } from './SchedulerContent'
interface Props {
	availableTimes: string[]
	timezone: string
	setTimezone?: (timezone: string) => void
	errorSlots?: string[]
	locale: string
	webinarSlots?: WebinarSlot[]
	maxWebinarAttendees?: number
	isWebinarEnabled?: boolean
	isScheduling: boolean
	handleScheduleMeeting?: (timestamp: string, webinarMemberId?: string) => void
	title?: string
	description?: string
	styles: StylesType
	profilePic?: string | null
	profileName?: string | null
	isAvailabilityLoading?: boolean
	range?: DateRange
	setRange?: (range: DateRange) => void
	duration?: number
	previousSlot?: string
	handleKeepPreviousSlot?: () => void
}

interface ContextType extends Props {
	calendarState: CalendarState
	timeslot: string
	setTimeslot: (timeslot: string) => void
	handleNextMonth: () => void
	selectedDate?: Date
	clickedDate?: CalendarDate | null
	setClickedDate: (date: CalendarDate | null) => void
}

const SchedulerContext = React.createContext<ContextType>({} as ContextType)

export const Scheduler = (props: Props) => {
	const defaultDate = useMemo(
		() => getDefaultDate(props.availableTimes, props.timezone),
		[props.availableTimes, props.timezone],
	)

	const state = useCalendarState({
		locale: props.locale,
		createCalendar,
		visibleDuration: { months: 1 },
		minValue: today(props.timezone),
		defaultValue: defaultDate,
		isDateUnavailable: (d) => {
			const date = d.toDate(props.timezone)
			return isDateNotAvailable(props.availableTimes, date, props.timezone)
		},
	})

	useEffect(() => {
		if (!state.value || state.isCellUnavailable(state.value)) {
			state.setValue(defaultDate)
			state.setFocusedDate(defaultDate)
		}
	}, [defaultDate])

	const [clickedDate, setClickedDate] = useState<CalendarDate | null>(null)
	const [timeslot, setTimeslot] = useState('')

	const handleNextMonth = () => {
		if (props.setRange && props.range) {
			props.setRange({
				start: props.range.end,
				end: props.range.end + getMillisecondsOfDays(30),
			})
		}
		state.focusNextPage()
	}

	const selectedDate = state.value?.toDate(props.timezone)

	return (
		<Flex
			sx={{
				containerType: 'inline-size',
				containerName: CALENDAR_RESPONSIVE_CONTAINER,
			}}
			w="full"
			h="full"
			direction="column"
			alignItems="center"
		>
			<SchedulerContext.Provider
				value={{
					calendarState: state,
					timeslot,
					setTimeslot,
					handleNextMonth,
					selectedDate,
					clickedDate,
					setClickedDate,
					...props,
				}}
			>
				<SchedulerContent />
			</SchedulerContext.Provider>
		</Flex>
	)
}

export const useSchedulerContext = () => {
	const context = React.useContext(SchedulerContext)
	if (context === undefined) {
		throw new Error(
			'useSchedulerContext must be used within a SchedulerProvider',
		)
	}
	return context
}
