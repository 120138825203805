import { useMediaQuery } from '@chakra-ui/react'
import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import { isProd } from '@/app/config'
import { ResourceGroups } from '@/app/roles/types'
import { LoadingPage } from '@/components/Loading'
import { useTrackPreviousRoute } from '@/hooks/routes'
import PermissionGuard from '@/routing/PermissionGuard'

import { DEEP_ROUTES, ROUTES } from '../../routing/routes'
import { ScreenSizeConstraints } from './components/ScreenSizeConstraint'
import { HomePage } from './page/HomePage'
import { AuthPartial } from './partials/AuthPartial'

const OnBoardingPage = lazy(() => import('../onboarding'))
const LoginPage = lazy(() => import('../login'))

export const App = () => {
	useTrackPreviousRoute()

	const [showApplication] = useMediaQuery('(min-width: 1120px)')

	if (!showApplication && isProd) {
		return <ScreenSizeConstraints />
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<AuthPartial>
				<Routes>
					<Route
						element={
							<PermissionGuard resourceGroup={ResourceGroups.GETTING_STARTED} />
						}
					>
						<Route path={DEEP_ROUTES.ONBOARDING} element={<OnBoardingPage />} />
					</Route>
					<Route path={ROUTES.LOGIN} element={<LoginPage />} />
					<Route path={DEEP_ROUTES.INDEX} element={<HomePage />} />
				</Routes>
			</AuthPartial>
		</Suspense>
	)
}
