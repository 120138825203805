import { Button, Flex, HStack, Text } from '@chakra-ui/react'
import { isSameDay, parseAbsolute } from '@internationalized/date'
import moment from 'moment-timezone'
import { useEffect, useMemo } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'
import { SchedulerTranslationsTokens } from 'shared-utils'

import { partitionSlots } from '../../../common/utils/partitionSlots'
import { GetIcon, Icons } from '../../../Icons'
import { CALENDER_QUERIES } from '../../Calendar/styles/responsive'
import { useSchedulerContext } from '..'
import { TimeSlotSectionLoading } from '../Loadings/TimeSlotSectionLoading'
import {
	createColorFromPrimaryText,
	getSecondaryButtonHoverBorderColor,
} from '../utils/colors'
import { TimeslotsHeader } from './TimeslotsHeader'

export const Timeslots = ({ isSelectedDate }: { isSelectedDate: boolean }) => {
	const { isAvailabilityLoading } = useSchedulerContext()

	return (
		<Flex
			flexDir="column"
			gap="12px"
			w="220px"
			flexBasis="0px"
			flexGrow={1}
			overflowY="auto"
			className="hide-scrollbar"
			sx={{
				[CALENDER_QUERIES.sm]: {
					w: isSelectedDate ? 'full' : '220px',
					flexGrow: isSelectedDate ? 0 : 1,
					flexBasis: isSelectedDate ? 'auto' : '0px',
				},
			}}
		>
			<TimeslotsHeader />
			{isAvailabilityLoading ? <TimeSlotSectionLoading /> : <TimePicker />}
		</Flex>
	)
}

const TimePicker = () => {
	const { calendarState, availableTimes, timezone } = useSchedulerContext()

	const allSlots = availableTimes.filter((ad) =>
		isSameDay(parseAbsolute(ad, timezone), calendarState.value as any),
	)

	const { morningSlots, afternoonSlots, eveningSlots } = useMemo(
		() => partitionSlots(allSlots, timezone),
		[allSlots, timezone],
	)

	const intl = useIntl()

	return (
		<Flex w="full" h="full" flexDir="column" gap="16px">
			<Zone
				slots={morningSlots}
				name={intl.formatMessage({ id: SchedulerTranslationsTokens.morning })}
				icon={Icons.sunset}
			/>
			<Zone
				slots={afternoonSlots}
				name={intl.formatMessage({ id: SchedulerTranslationsTokens.daytime })}
				icon={Icons.sun}
			/>
			<Zone
				slots={eveningSlots}
				name={intl.formatMessage({ id: SchedulerTranslationsTokens.evening })}
				icon={Icons.moon}
			/>
		</Flex>
	)
}

const Zone = ({
	slots,
	name,
	icon,
}: {
	slots: string[]
	name: string
	icon: Icons
}) => {
	const { styles } = useSchedulerContext()
	if (slots.length === 0) return null

	const labelColor = createColorFromPrimaryText(styles.text_primary)
		.fade(0.4)
		.hexa()

	return (
		<Flex w="full" direction="column" gap="16px">
			<HStack alignItems="center" gap="8px">
				<GetIcon icon={icon} color={labelColor} />
				<Text fontSize="14px" fontWeight={400} color={labelColor}>
					{name}
				</Text>
			</HStack>

			<Flex w="full" flexDir="column" gap="8px">
				{slots?.map((s, i) => {
					return <Slot key={s} slot={s} i={i} />
				})}
			</Flex>
		</Flex>
	)
}

const Slot = React.memo(({ slot, i }: { slot: string; i: number }) => {
	const id = slot
	const {
		styles,
		webinarSlots,
		maxWebinarAttendees,
		isWebinarEnabled,
		timezone,
		isScheduling,
		handleScheduleMeeting,
		timeslot,
		setTimeslot,
		locale,
		errorSlots,
	} = useSchedulerContext()

	const isErrored = errorSlots?.includes(slot)

	const [zIndex, setZIndex] = React.useState(1)

	const clicked = id === timeslot

	const [loadingButton, setLoadingButton] = React.useState('')

	const intl = useIntl()

	const dateWithTimezoneLocale = moment(slot).tz(timezone).locale(locale)
	const selectedWebinarSlot = webinarSlots?.find((ws) => {
		return slot.toString() === ws.timeSlot.toString()
	})

	const formattedTime = dateWithTimezoneLocale.format('h:mm A').toString()

	const isLoading = clicked && loadingButton === id && isScheduling

	const spotsNumber =
		selectedWebinarSlot?.available_slots || maxWebinarAttendees

	useEffect(() => {
		if (!clicked) {
			setTimeout(() => {
				setZIndex(1)
			}, 201)
			return
		}
		setZIndex(20)
	}, [clicked])

	const handleOnClick = () => {
		if (isScheduling) return
		if (clicked && handleScheduleMeeting) {
			handleScheduleMeeting(slot, selectedWebinarSlot?.memberId)
			setLoadingButton(id)
		} else {
			setTimeslot(id)
		}
	}

	useEffect(() => {
		if (isErrored) {
			setTimeslot('')
		}
	}, [isErrored])

	const hoverBorderColor = getSecondaryButtonHoverBorderColor(
		styles.border_primary,
	)

	return (
		<Flex
			w="full"
			flexDirection="column"
			position="relative"
			zIndex={zIndex}
			mt={i === 0 ? '-8px' : 0}
		>
			<Flex
				pos="absolute"
				bg={styles.btn_bg_tertiary}
				color="#fff"
				w="full"
				h={clicked ? '46px' : '30px'}
				borderTopLeftRadius="6px"
				borderTopRightRadius="6px"
				mt={clicked ? '-40px' : '2px'}
				transition="margin-top 200ms ease, height 200ms ease"
				opacity={!clicked && styles.is_translucent ? 0 : 1}
				flexWrap="wrap"
				alignItems="center"
				justifyContent="center"
				overflow="hidden"
				alignContent="center"
				pb={1}
			>
				<Text fontSize="12px" fontWeight={600} noOfLines={1} mr="2px">
					{dateWithTimezoneLocale.format('MMM D h:mm A')}
				</Text>
				<Text fontSize="10px" fontWeight={500} noOfLines={1}>
					({`${timezone}`})
				</Text>
			</Flex>

			<Button
				key={slot}
				onClick={(e) => {
					e.stopPropagation()
					handleOnClick()
				}}
				zIndex={10}
				w="full"
				h={spotsNumber !== undefined && isWebinarEnabled ? '50px' : '36px'}
				minH="36px"
				rounded="6px"
				border="1px solid"
				borderTop={clicked ? 'none' : '1px solid'}
				borderColor={clicked ? 'transparent' : styles.border_primary}
				color={
					clicked || isLoading ? styles.btn_color_primary : styles.text_primary
				}
				boxShadow="none"
				isLoading={isLoading}
				_disabled={{
					opacity: 1,
					cursor: 'not-allowed',
				}}
				bg={
					clicked || isLoading
						? styles.btn_bg_primary + '!important'
						: styles.bg_secondary
				}
				_hover={{
					bg: clicked ? styles.btn_bg_primary : styles.bg_secondary,
					borderColor: clicked ? 'transparent' : hoverBorderColor,
					_disabled: {
						bg: styles.bg_secondary,
						borderColor: styles.border_primary,
					},
				}}
				isDisabled={isErrored}
				flexDir="column"
				gap="4px"
			>
				<Text
					fontSize="14px"
					fontWeight={400}
					textDecoration={isErrored ? 'line-through' : 'none'}
				>
					{clicked
						? intl.formatMessage({ id: SchedulerTranslationsTokens.confirm })
						: formattedTime}
				</Text>
				{spotsNumber !== undefined && isWebinarEnabled && (
					<Text fontSize="12px" fontWeight={400} noOfLines={1}>
						{spotsNumber} spot
						{spotsNumber === 1 ? '' : 's'} left
					</Text>
				)}
			</Button>
		</Flex>
	)
})

Slot.displayName = 'Slot'
