export const TIME_ZONES_GERMAN = [
	{
		label: 'USA/Kanada',
		options: [
			{
				label: 'Pazifische Zeit - USA & Kanada',
				value: 'America/Los_Angeles',
			},
			{
				label: 'Mountain Zeit - USA & Kanada',
				value: 'America/Denver',
			},
			{
				label: 'Zentrale Zeit - USA & Kanada',
				value: 'America/Chicago',
			},
			{
				label: 'Östliche Zeit - USA & Kanada',
				value: 'America/New_York',
			},
			{
				label: 'Alaska Zeit',
				value: 'America/Anchorage',
			},
			{
				label: 'Arizona, Yukon Zeit',
				value: 'America/Phoenix',
			},
			{
				label: 'Neufundland Zeit',
				value: 'America/St_Johns',
			},
			{
				label: 'Hawaii Zeit',
				value: 'Pacific/Honolulu',
			},
		],
	},
	{
		label: 'Amerika',
		options: [
			{
				label: 'Amerika/Adak',
				value: 'America/Adak',
			},
			{
				label: 'Buenos Aires Zeit',
				value: 'America/Argentina/Buenos_Aires',
			},
			{
				label: 'Asunción Zeit',
				value: 'America/Asuncion',
			},
			{
				label: 'Bogotá, Jamaika, Lima Zeit',
				value: 'America/Bogota',
			},
			{
				label: 'Amerika/Campo Grande',
				value: 'America/Campo_Grande',
			},
			{
				label: 'Caracas Zeit',
				value: 'America/Caracas',
			},
			{
				label: 'Amerika/Godthab',
				value: 'America/Godthab',
			},
			{
				label: 'Atlantische Zeit',
				value: 'America/Halifax',
			},
			{
				label: 'Saskatchewan, Guatemala, Costa Rica Zeit',
				value: 'America/Regina',
			},
			{
				label: 'Amerika/Havanna',
				value: 'America/Havana',
			},
			{
				label: 'Amerika/Mazatlán',
				value: 'America/Mazatlan',
			},
			{
				label: 'Mexiko-Stadt Zeit',
				value: 'America/Mexico_City',
			},
			{
				label: 'Montevideo Zeit',
				value: 'America/Montevideo',
			},
			{
				label: 'Amerika/Miquelon',
				value: 'America/Miquelon',
			},
			{
				label: 'Amerika/Noronha',
				value: 'America/Noronha',
			},
			{
				label: 'Santiago Zeit',
				value: 'America/Santiago',
			},
			{
				label: 'Amerika/Santa Isabel',
				value: 'America/Santa_Isabel',
			},
			{
				label: 'Atlantische Standardzeit',
				value: 'America/Thule',
			},
			{
				label: 'Brasília Zeit',
				value: 'America/Sao_Paulo',
			},
		],
	},
	{
		label: 'Afrika',
		options: [
			{
				label: 'Afrika/Kairo',
				value: 'Africa/Cairo',
			},
			{
				label: 'Zentralafrika Zeit',
				value: 'Africa/Maputo',
			},
			{
				label: 'Westafrika Zeit',
				value: 'Africa/Lagos',
			},
			{
				label: 'Afrika/Windhoek',
				value: 'Africa/Windhoek',
			},
		],
	},
	{
		label: 'ASIEN',
		options: [
			{
				label: 'Jordanien Zeit',
				value: 'Asia/Amman',
			},
			{
				label: 'Bagdad, Ostafrika Zeit',
				value: 'Asia/Baghdad',
			},
			{
				label: 'Asien/Baku',
				value: 'Asia/Baku',
			},
			{
				label: 'Libanon Zeit',
				value: 'Asia/Beirut',
			},
			{
				label: 'Syrien Zeit',
				value: 'Asia/Damascus',
			},
			{
				label: 'Asien/Dhaka',
				value: 'Asia/Dhaka',
			},
			{
				label: 'Dubai Zeit',
				value: 'Asia/Dubai',
			},
			{
				label: 'Asien/Gaza',
				value: 'Asia/Gaza',
			},
			{
				label: 'Asien/Irkutsk',
				value: 'Asia/Irkutsk',
			},
			{
				label: 'Indochina Zeit',
				value: 'Asia/Bangkok',
			},
			{
				label: 'Israel Zeit',
				value: 'Asia/Jerusalem',
			},
			{
				label: 'Kabul Zeit',
				value: 'Asia/Kabul',
			},
			{
				label: 'Pazifik/Majuro',
				value: 'Pacific/Majuro',
			},
			{
				label: 'Pakistan, Malediven Zeit',
				value: 'Asia/Karachi',
			},
			{
				label: 'Kathmandu Zeit',
				value: 'Asia/Kathmandu',
			},
			{
				label: 'Indien, Sri Lanka Zeit',
				value: 'Asia/Calcutta',
			},
			{
				label: 'Krasnojarsk Zeit',
				value: 'Asia/Krasnoyarsk',
			},
			{
				label: 'Asien/Omsk',
				value: 'Asia/Omsk',
			},
			{
				label: 'Asien/Rangun',
				value: 'Asia/Yangon',
			},
			{
				label: 'China, Singapur, Perth',
				value: 'Asia/Shanghai',
			},
			{
				label: 'Teheran Zeit',
				value: 'Asia/Tehran',
			},
			{
				label: 'Japan, Korea Zeit',
				value: 'Asia/Tokyo',
			},
			{
				label: 'Asien/Wladiwostok',
				value: 'Asia/Vladivostok',
			},
			{
				label: 'Asien/Jakutsk',
				value: 'Asia/Yakutsk',
			},
			{
				label: 'Jekaterinburg Zeit',
				value: 'Asia/Yekaterinburg',
			},
			{
				label: 'Asien/Jerewan',
				value: 'Asia/Yerevan',
			},
		],
	},
	{
		label: 'Atlantik',
		options: [
			{
				label: 'Azoren Zeit',
				value: 'Atlantic/Azores',
			},
			{
				label: 'Kap Verde Zeit',
				value: 'Atlantic/Cape_Verde',
			},
		],
	},
	{
		label: 'Australien',
		options: [
			{
				label: 'Adelaide Zeit',
				value: 'Australia/Adelaide',
			},
			{
				label: 'Brisbane Zeit',
				value: 'Australia/Brisbane',
			},
			{
				label: 'Australien/Darwin',
				value: 'Australia/Darwin',
			},
			{
				label: 'Australien/Eucla',
				value: 'Australia/Eucla',
			},
			{
				label: 'Australien/Lord Howe',
				value: 'Australia/Lord_Howe',
			},
			{
				label: 'Australien/Perth',
				value: 'Australia/Perth',
			},
			{
				label: 'Sydney, Melbourne Zeit',
				value: 'Australia/Sydney',
			},
		],
	},
	{
		label: 'UTC',
		options: [
			{
				label: 'UTC Zeit',
				value: 'Etc/UTC',
			},
		],
	},
	{
		label: 'Europa',
		options: [
			{
				label: 'Mitteleuropäische Zeit',
				value: 'Europe/Berlin',
			},
			{
				label: 'Osteuropäische Zeit',
				value: 'Europe/Bucharest',
			},
			{
				label: 'Großbritannien, Irland, Lissabon Zeit',
				value: 'Europe/London',
			},
			{
				label: 'Minsk Zeit',
				value: 'Europe/Minsk',
			},
			{
				label: 'Moskauer Zeit',
				value: 'Europe/Moscow',
			},
			{
				label: 'Türkei Zeit',
				value: 'Europe/Istanbul',
			},
		],
	},
	{
		label: 'Pazifik',
		options: [
			{
				label: 'Pazifik/Apia',
				value: 'Pacific/Apia',
			},
			{
				label: 'Auckland Zeit',
				value: 'Pacific/Auckland',
			},
			{
				label: 'Pazifik/Chatham',
				value: 'Pacific/Chatham',
			},
			{
				label: 'Pazifik/Ostern',
				value: 'Pacific/Easter',
			},
			{
				label: 'Pazifik/Fidschi',
				value: 'Pacific/Fiji',
			},
			{
				label: 'Pazifik/Gambier',
				value: 'Pacific/Gambier',
			},
			{
				label: 'Pazifik/Kiritimati',
				value: 'Pacific/Kiritimati',
			},
			{
				label: 'Pazifik/Majuro',
				value: 'Pacific/Majuro',
			},
			{
				label: 'Pazifik/Marquesas',
				value: 'Pacific/Marquesas',
			},
			{
				label: 'Pazifik/Norfolk',
				value: 'Pacific/Norfolk',
			},
			{
				label: 'Pazifik/Noumea',
				value: 'Pacific/Noumea',
			},
			{
				label: 'Pazifik/Pago Pago',
				value: 'Pacific/Pago_Pago',
			},
			{
				label: 'Pazifik/Pitcairn',
				value: 'Pacific/Pitcairn',
			},
			{
				label: 'Pazifik/Tarawa',
				value: 'Pacific/Tarawa',
			},
			{
				label: 'Pazifik/Tongatapu',
				value: 'Pacific/Tongatapu',
			},
		],
	},
]
