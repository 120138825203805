import { ResourceGroups } from '../types'
import { useGetRoleForCurrentUserQuery } from '.'

export const useCurrentRolePermissions = () => {
	return useGetRoleForCurrentUserQuery(undefined, {
		selectFromResult: (result) => {
			return {
				permissions: result.data?.permissions,
			}
		},
	})
}

export const useMemberHasWritePermission = (resourceGroup: ResourceGroups) => {
	const { permissions } = useCurrentRolePermissions()
	return permissions?.[resourceGroup]?.write || false
}

export const useMemberHasReadPermission = (resourceGroup: ResourceGroups) => {
	const { permissions } = useCurrentRolePermissions()
	return permissions?.[resourceGroup]?.read || false
}

export const useMemberCanWriteWorkflows = () => {
	return useMemberHasWritePermission(ResourceGroups.WORKFLOWS)
}

export const useMemberCanReadWorkflows = () => {
	return useMemberHasReadPermission(ResourceGroups.WORKFLOWS)
}

export const useMemberCanWriteQueues = () => {
	return useMemberHasWritePermission(ResourceGroups.QUEUES)
}

export const useMemberCanReadQueues = () => {
	return useMemberHasReadPermission(ResourceGroups.QUEUES)
}

export const useMemberCanWriteMeetingReminders = () => {
	return useMemberHasWritePermission(ResourceGroups.MEETING_REMINDERS)
}

export const useMemberCanReadMeetingReminders = () => {
	return useMemberHasReadPermission(ResourceGroups.MEETING_REMINDERS)
}

export const useMemberCanWriteSchedulerStyle = () => {
	return useMemberHasWritePermission(ResourceGroups.SCHEDULER_STYLING)
}

export const useMemberCanReadSchedulerStyle = () => {
	return useMemberHasReadPermission(ResourceGroups.SCHEDULER_STYLING)
}

export const useMemberCanWriteSchedulerMeetings = () => {
	return useMemberHasWritePermission(ResourceGroups.SCHEDULER_MEETINGS)
}

export const useMemberCanReadSchedulerMeetings = () => {
	return useMemberHasReadPermission(ResourceGroups.SCHEDULER_MEETINGS)
}

export const useMemberCanWriteEventTypes = () => {
	return useMemberHasWritePermission(ResourceGroups.EVENT_TYPES)
}

export const useMemberCanReadEventTypes = () => {
	return useMemberHasReadPermission(ResourceGroups.EVENT_TYPES)
}

export const useMemberCanWriteForms = () => {
	return useMemberHasWritePermission(ResourceGroups.FORMS)
}

export const useMemberCanReadForms = () => {
	return useMemberHasReadPermission(ResourceGroups.FORMS)
}

export const useMemberCanWriteWorkspaceSettings = () => {
	return useMemberHasWritePermission(ResourceGroups.WORKSPACE_SETTINGS)
}

export const useMemberCanReadWorkspaceSettings = () => {
	return useMemberHasReadPermission(ResourceGroups.WORKSPACE_SETTINGS)
}

export const useMemberCanWriteSettingsConfiguration = () => {
	return useMemberHasWritePermission(ResourceGroups.SETTINGS_CONFIGURATION)
}

export const useMemberCanReadSettingsConfiguration = () => {
	return useMemberHasReadPermission(ResourceGroups.SETTINGS_CONFIGURATION)
}

export const useMemberCanReadGettingStarted = () => {
	return useMemberHasReadPermission(ResourceGroups.GETTING_STARTED)
}

export const usePermissions = () => {
	const { permissions } = useCurrentRolePermissions()
	return permissions
}
