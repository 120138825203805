import { Flex, Grid, GridItem, Text } from '@chakra-ui/layout'
import { CalendarDate, getWeeksInMonth } from '@internationalized/date'
import { CalendarState } from '@react-stately/calendar'
import { useIntl } from 'react-intl'
import { SchedulerTranslationsTokens } from 'shared-utils'

import { CALENDER_QUERIES } from '../../Calendar/styles/responsive'
import { useSchedulerContext } from '..'
import { createColorFromPrimaryText } from '../utils/colors'
import { CalendarCell } from './CalendarCell'

export const CalendarGrid = () => {
	return (
		<Flex w="full" h="full" direction="column">
			<WeekDaysRow />
			<WeekCells />
		</Flex>
	)
}

const WeekDaysRow = () => {
	const intl = useIntl()
	const { styles } = useSchedulerContext()
	const WEEKS = intl
		.formatMessage({ id: SchedulerTranslationsTokens.weekdays_short })
		.split('_')

	const baseColor = createColorFromPrimaryText(styles.text_primary)

	return (
		<Grid w="full" templateColumns="repeat(7, 1fr)" gap="4px">
			{WEEKS.map((day, i) => (
				<GridItem
					key={i}
					justifyContent="center"
					alignItems="center"
					p={4}
					sx={{
						[CALENDER_QUERIES.xs]: {
							p: '10px',
						},
					}}
				>
					<Text
						fontSize="12px"
						fontWeight={500}
						color={baseColor.fade(0.35).hexa()}
					>
						{day}
					</Text>
				</GridItem>
			))}
		</Grid>
	)
}

const WeekCells = () => {
	const { calendarState } = useSchedulerContext()

	if (!calendarState) return null

	const getMonthWeeks = (calendarState: CalendarState): CalendarDate[][] => {
		const weeksInMonth = getWeeksInMonth(
			calendarState.visibleRange.start,
			'en-US',
		)
		return Array.from({ length: weeksInMonth }, (_, weekIndex) =>
			calendarState
				.getDatesInWeek(weekIndex, calendarState.visibleRange.start)
				.filter((date): date is CalendarDate => date !== null),
		)
	}

	const weeks = getMonthWeeks(calendarState)

	return (
		<Grid w="100%" templateColumns="repeat(7, 1fr)" gap="4px" fontWeight={500}>
			{weeks.map((week) =>
				week.map((date, i) => {
					if (date) {
						return <CalendarCell key={i} date={date} />
					}
				}),
			)}
		</Grid>
	)
}
