import { Center } from '@chakra-ui/react'

import { CALENDER_QUERIES } from '../../Calendar/styles/responsive'
import { useSchedulerContext } from '..'

export const CalendarCellLoading = () => {
	const { styles } = useSchedulerContext()

	return (
		<Center
			h="45px"
			w="45px"
			bg={`radial-gradient(circle, ${styles.loading_gradient_2} 0%, ${styles.loading_gradient_1} 100%)`}
			borderRadius="full"
			sx={{
				[CALENDER_QUERIES.xs]: {
					w: '36px',
					h: '36px',
				},
			}}
		/>
	)
}
