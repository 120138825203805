import ReactSelect, { GroupBase, Props } from 'react-select'

import { StylesType } from '../../../../styles'
import { DropdownIndicator } from './components/DropdownIndicator'
import { Option } from './components/Option'
import { SingleValue } from './components/SingleValue'
import { mergeSelectStyles } from './utils/merge-styles'

type CustomProps = {
	defaultStyles?: StylesType
}

export const Select = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>({
	components,
	styles,
	defaultStyles,
	...props
}: Props<Option, IsMulti, Group> & CustomProps) => {
	const mergeStyles = mergeSelectStyles({
		defaultStyles,
		customStyles: styles,
	})

	return (
		<ReactSelect
			styles={mergeStyles}
			components={{
				IndicatorSeparator: () => null,
				DropdownIndicator: (props) => (
					<DropdownIndicator defaultStyles={defaultStyles} {...props} />
				),
				Option: (props) => <Option {...props} />,
				SingleValue: SingleValue,
				...components,
			}}
			isSearchable={props.isSearchable || false}
			{...props}
		/>
	)
}
