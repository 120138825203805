import type { ComponentStyleConfig } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/legacy/colors'
import { hexToHsl } from 'shared-utils'

import { Borders, Radii } from '../borders'
import { SHADOWS } from '../constants'
import { SPACING } from '../space'
import { FONT_SIZES, WEIGHT } from '../typography'

export enum BTN {
	primary = 'primary',
	secondary = 'secondary',
	outline = 'outline',
	outlined_secondary = 'outlined_secondary',

	tertiary = 'tertiary',
	default = 'default',
	integrations = 'integrations',
	link = 'link',
	hyperlink = 'hyperlink',
	text = 'text',
	ghost = 'ghost',
	danger_primary = 'danger_primary',
	danger_secondary = 'danger_secondary',
	danger_tertiary = 'danger_tertiary',

	primary_icon = 'primary_icon',
	workflows_side_panel = 'workflows_side_panel',
	workflows_side_panel_primary = 'workflows_side_panel_primary',
	workflows_side_panel_primary_experimental = 'workflows_side_panel_primary_experimental',
	workflows_side_panel_danger = 'workflows_side_panel_danger',

	/** V2 */
	primary_v2 = 'primary_v2',
	secondary_v2 = 'secondary_v2',
	outline_v2 = 'outline_v2',
	outline_secondary_v2 = 'outline_secondary_v2',
	danger_v2 = 'danger_v2',

	/** V3 */
	primary_v3 = 'primary_v3',
}

const Button: ComponentStyleConfig = {
	baseStyle: {
		bg: 'transparent',
		_focus: {
			boxShadow: 'none',
		},
	},
	sizes: {
		xs: {
			h: '24px',
			px: '12px',
			fontSize: '14px',
		},
		sm: {
			h: '32px',
			px: '12px',
			fontSize: '14px',
		},
		md: {
			h: '36px',
			px: '12px',
			fontSize: '14px',
		},
		lg: {
			h: '40px',
			px: '12px',
			fontSize: '14px',
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'md',
	},
	variants: {
		[BTN.primary]: {
			bg: COLORS.purple[9],
			color: ColorTokens.white,
			borderRadius: Radii.md,

			fontWeight: WEIGHT.semibold,
			fontSize: FONT_SIZES.sm,
			height: SPACING[10],
			lineHeight: SPACING[5],
			border: '1px solid',
			borderColor: 'transparent',

			_hover: {
				background: COLORS.purple[10],
				_disabled: {
					background: COLORS.purple[9],
				},
			},
			_active: { background: COLORS.purple[11] },
			_focusVisible: {
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},
			_disabled: {
				background: COLORS.purple[9],
				opacity: 0.5,
			},
		},

		[BTN.secondary]: {
			bg: ColorTokens.white,
			color: COLORS.gray[12],
			borderRadius: Radii.md,
			fontWeight: WEIGHT.medium,
			fontSize: FONT_SIZES.sm,
			height: SPACING[10],
			lineHeight: SPACING[5],
			boxShadow: SHADOWS.depth0,
			border: Borders.secondary_btn,
			_hover: {
				borderColor: COLORS.background[9],
				_disabled: {
					border: Borders.secondary_btn,
				},
			},
			_active: { background: COLORS.background[12] },
			_focusVisible: {
				borderColor: COLORS.background[10],
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},

			_disabled: {
				opacity: 0.5,
			},
		},

		[BTN.default]: () => ({
			bg: ColorTokens.white,
			color: COLORS.gray[12],
			boxShadow: 'medium',
			borderWidth: '1px',
			fontWeight: 600,
			borderColor: '#E0E2E9',
			fontSize: 'sm',
			height: 10,
		}),

		[BTN.link]: () => ({
			bg: 'transparent',
			fontSize: 'xs',
			mt: '2px',
			color: ColorTokens.text_secondary,
			_hover: {
				textDecoration: 'none',
				color: '#222B48',
			},
			_active: {
				color: '#222B48',
			},
		}),
		[BTN.hyperlink]: () => ({
			bg: 'transparent',
			fontSize: 'sm',
			fontWeight: 500,
			mt: '2px',
			color: ColorTokens.accent,
			_hover: {
				textDecoration: 'underline',
				textUnderlineOffset: 4,
				color: ColorTokens.accent,
			},
			_active: {
				color: ColorTokens.accent,
			},
		}),
		[BTN.tertiary]: () => ({
			bg: 'rgba(0,0,0,0)',
			color: COLORS.gray[10],
			fontSize: 'sm',
			height: 9,
			borderWidth: '1px',
			borderColor: 'transparent',
			_hover: {
				background: COLORS.background[3],
			},
			_active: {
				background: COLORS.background[3],
				color: ColorTokens.text,
			},
		}),
		[BTN.outline]: {
			color: ColorTokens.accent,
			borderWidth: 'medium',
			borderColor: ColorTokens.accent,
			boxShadow: 'medium',
			fontWeight: 600,
			fontSize: 'sm',
			height: 9,
			// TODO: change hex codes with new theme colors
			_hover: { background: '#EDEDFF' },
			_active: { background: '#E5E5FF' },
		},
		[BTN.integrations]: () => ({
			bg: ColorTokens.white,
			boxShadow: 'medium',
			borderWidth: '1px',
			fontWeight: 600,
			borderColor: ColorTokens.border_primary,
			fontSize: '13px',
			height: 8,
		}),
		[BTN.ghost]: () => ({
			background: 'rgba(255,255,255,0)',
			color: '#AAADB6',
			letterSpacing: '0.2px',
			fontSize: 'sm',
			fontWeight: 500,
			_hover: {
				background: 'rgba(37,17,17,0.06)',
				color: '#021030',
			},
		}),
		[BTN.danger_primary]: () => ({
			bg: ColorTokens.white,
			boxShadow: '0px 1px 3px rgba(12, 17, 61, 0.08)',
			borderWidth: '1px',
			fontWeight: 600,
			height: 9,
			borderColor: '#E0E2E9',
			fontSize: 'sm',
			_hover: {
				background: 'rgba(209, 40, 71,0.18)',
				color: '#fc4768',
				borderColor: '#fc4768',
			},
		}),
		[BTN.danger_secondary]: () => ({
			bg: COLORS.red[2],
			color: COLORS.red[9],
			fontSize: 'sm',
			letterSpacing: '0.2px',
			borderWidth: '1px',
			borderColor: COLORS.red[2],
			_hover: {
				background: COLORS.red[3],
				borderColor: COLORS.red[3],
			},
		}),
		[BTN.danger_tertiary]: () => ({
			bg: ColorTokens.white,
			boxShadow: '0px 1px 3px rgba(12, 17, 61, 0.08)',
			borderWidth: '1px',
			fontWeight: 600,
			borderColor: '#E0E2E9',
			fontSize: 'sm',
			_hover: {
				background: '#FFEBEF',
				color: '#F32451',
				borderColor: '#F32451',
			},
			_active: {
				background: '#FFC4D1',
				color: '#F32451',
				borderColor: '#F32451',
			},
		}),
		[BTN.primary_icon]: {
			bg: 'transparent',
			border: 'none',
			borderRadius: '6px',
			outline: 'none',
			h: '32px',
			minW: '32px',
			_hover: {
				bg: ColorTokens.bg_hover,
			},
		},
		[BTN.workflows_side_panel]: {
			bg: ColorTokens.white,
			width: 'fit-content',
			color: COLORS.gray[12],
			borderRadius: Radii.lg,
			fontWeight: WEIGHT.medium,
			fontSize: FONT_SIZES.sm,
			height: 8,
			py: SPACING[1.5],
			px: SPACING[3],
			boxShadow: SHADOWS.button_secondary,
			_hover: {
				borderColor: COLORS.background[9],
				_disabled: {
					border: Borders.secondary_btn,
				},
			},
			_active: { background: COLORS.background[12] },
			_focus: {
				boxShadow: SHADOWS.button_secondary,
			},
			_focusVisible: {
				borderColor: COLORS.background[10],
				boxShadow:
					'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
			},

			_disabled: {
				opacity: 0.5,
			},
		},
		/** V2 */
		[BTN.primary_v2]: {
			bg: COLORS.purple[9],
			color: ColorTokens.white,
			borderRadius: '8px',
			fontWeight: 500,
			border: 'none',
			boxShadow: SHADOWS.primary_button_v2,
			_hover: {
				background: COLORS.purple[10],
				_disabled: {
					background: COLORS.purple[6],
				},
			},
			_active: { background: COLORS.purple[11] },
			_focusVisible: {
				boxShadow: SHADOWS.primary_button_focus_v2,
			},
			_disabled: {
				background: COLORS.purple[6],
			},
		},
		[BTN.secondary_v2]: {
			bg: ColorTokens.white,
			color: COLORS.background[2],
			borderRadius: '8px',
			fontWeight: 500,
			boxShadow: SHADOWS.secondary_button_v2,
			border: '1px solid',
			borderColor: 'transparent',
			outline: 'none',
			_hover: {
				bg: COLORS.background[12],
				borderColor: COLORS.background[12],
				_disabled: {
					borderColor: COLORS.gray[5],
				},
			},
			_active: {
				bg: COLORS.background[12],
				borderColor: COLORS.background[12],
				_disabled: {
					borderColor: COLORS.gray[5],
				},
			},
			_focus: {
				bg: COLORS.background[12],
				borderColor: COLORS.background[12],
				boxShadow: SHADOWS.secondary_button_v2,
				_disabled: {
					borderColor: COLORS.gray[5],
				},
			},
			_focusVisible: {
				boxShadow: SHADOWS.secondary_button_focus_v2,
			},
			_disabled: {
				boxShadow: 'none',
				bg: COLORS.background[12],
				borderColor: COLORS.gray[5],
			},
		},
		[BTN.outline_v2]: {
			bg: 'transparent',
			color: COLORS.purple[9],
			borderRadius: '8px',
			fontWeight: 500,
			border: '1px solid',
			borderColor: COLORS.purple[9],
			_hover: {
				color: COLORS.purple[10],
				background: COLORS.purple[3],
				borderColor: COLORS.purple[10],

				_disabled: {
					background: ColorTokens.white,
					borderColor: COLORS.purple[6],
				},
			},
			_focusVisible: {
				boxShadow: SHADOWS.primary_button_focus_v2,
			},
			_disabled: {
				background: ColorTokens.white,
				borderColor: COLORS.purple[6],
			},
		},
		[BTN.outline_secondary_v2]: {
			bg: 'transparent',
			color: ColorTokens.white,
			borderRadius: '8px',
			fontWeight: 500,
			border: '1px solid',
			borderColor: COLORS.background[5],
			_hover: {
				borderColor: COLORS.background[9],

				_disabled: {
					background: 'transparent',
					borderColor: COLORS.purple[5],
				},
			},
			_focusVisible: {
				boxShadow: SHADOWS.primary_button_focus_v2,
			},
			_disabled: {
				background: ColorTokens.white,
				borderColor: COLORS.background[5],
			},
		},
		[BTN.danger_v2]: {
			bg: COLORS.red[2],
			color: COLORS.red[9],
			borderRadius: '8px',
			fontWeight: 500,
			boxShadow: SHADOWS.danger_button_v2,
			border: '1px solid',
			borderColor: 'transparent',
			outline: 'none',
			_hover: {
				bg: COLORS.red[3],
			},
			_active: {
				bg: COLORS.background[12],
				borderColor: COLORS.background[12],
			},
			_focus: {
				bg: COLORS.background[12],
				borderColor: COLORS.background[12],
				boxShadow: SHADOWS.secondary_button_v2,
				_disabled: {
					borderColor: COLORS.gray[5],
				},
			},
			_focusVisible: {
				boxShadow: SHADOWS.secondary_button_focus_v2,
			},
			_disabled: {
				color: COLORS.red[6],
				boxShadow: 'none',
				bg: COLORS.red[2],
			},
		},
		[BTN.outlined_secondary]: {
			bg: 'transparent',
			color: '#666666',
			borderRadius: Radii.md,
			paddingX: 2,
			paddingY: 1,

			fontWeight: WEIGHT.medium,
			fontSize: FONT_SIZES.xs,
			minHeight: 6,
			height: 6,
			border: '1px solid',
			borderColor: ColorTokens.border_secondary_btn,

			_hover: {
				color: ColorTokens.text_primary,
				background: ColorTokens.bg_hover,
				borderColor: ColorTokens.border_secondary_btn,

				_disabled: {
					background: ColorTokens.bg_error,
					borderColor: ColorTokens.border_secondary_btn,
				},
			},
		},
	},
}

if (Button.variants) {
	Button.variants[BTN.workflows_side_panel_primary] = {
		...Button.variants?.[BTN.workflows_side_panel],
		bg: COLORS.purple[9],
		color: ColorTokens.white,
		_hover: {
			bg: COLORS.purple[9],
			borderColor: COLORS.purple[9],
			_disabled: {
				bg: COLORS.purple[9],
			},
		},
		_disabled: {
			_hover: {
				bg: COLORS.purple[9],
			},
			bg: COLORS.purple[9],
		},
	}
	Button.variants[BTN.workflows_side_panel_primary_experimental] = {
		...Button.variants?.[BTN.workflows_side_panel],
		bgImage: `linear-gradient(${COLORS.purple[8]},${COLORS.purple[10]} 39%,${COLORS.purple[9]})`,
		color: ColorTokens.white,
		boxShadow: `0 1px 3px hsl(0,0,0,.12),0 1px .5px rgba(0,0,0,.14),inset 0 1px .5px hsl(${hexToHsl(
			COLORS.purple[6],
		)},.4),inset 0 -1px .5px hsl(${hexToHsl(
			COLORS.purple[11],
		)},.46),inset 0 0 0 .5px ${hexToHsl(COLORS.purple[10])}`,
		border: `0.5px solid ${COLORS.purple[9]}`,
		_hover: {
			bgImage: `linear-gradient(${COLORS.purple[8]},${COLORS.purple[10]} 39%,${COLORS.purple[9]})`,
			borderColor: COLORS.purple[9],
			_disabled: {
				bgImage: `linear-gradient(${COLORS.purple[8]},${COLORS.purple[10]} 39%,${COLORS.purple[9]})`,
			},
		},
		_disabled: {
			_hover: {
				bgImage: `linear-gradient(${COLORS.purple[8]},${COLORS.purple[10]} 39%,${COLORS.purple[9]})`,
			},
			bgImage: `linear-gradient(${COLORS.purple[8]},${COLORS.purple[10]} 39%,${COLORS.purple[9]})`,
		},
	}
	Button.variants[BTN.workflows_side_panel_danger] = {
		...Button.variants?.[BTN.workflows_side_panel],
		bg: COLORS.red[2],
		color: COLORS.red[9],
		borderColor: COLORS.red[2],
		boxShadow: SHADOWS.workflows_side_panel_danger,
		// _hover: {
		// 	background: COLORS.red[3],
		// 	borderColor: COLORS.red[3],
		// },
	}
}

export default Button
