import { Button, Flex, Text } from '@chakra-ui/react'
import { isSameDay, parseAbsolute } from '@internationalized/date'
import moment from 'moment-timezone'
import { useEffect, useMemo } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'
import { SchedulerTranslationsTokens } from 'shared-utils'

import { useCalendarContext } from '../context'
import { TimeSlotSectionLoading } from '../Loadings/TimeSlotSectionLoading'
import { CALENDER_QUERIES } from '../styles/responsive'

const partitionSlots = (slots: string[], timezone: string) => {
	const morningSlots = slots.filter(
		(s) => moment(s).tz(timezone).get('hours') < 12,
	)
	const afternoonSlots = slots.filter(
		(s) =>
			moment(s).tz(timezone).get('hours') >= 12 &&
			moment(s).tz(timezone).get('hours') < 18,
	)
	const eveningSlots = slots.filter(
		(s) => moment(s).tz(timezone).get('hours') >= 18,
	)
	return { morningSlots, afternoonSlots, eveningSlots }
}

const TimePicker = () => {
	const { calendarState, availableTimes, timezone, isAvailabilityLoading } =
		useCalendarContext()

	const allSlots = availableTimes.filter((ad) =>
		isSameDay(parseAbsolute(ad, timezone), calendarState.value as any),
	)

	const { morningSlots, afternoonSlots, eveningSlots } = useMemo(
		() => partitionSlots(allSlots, timezone),
		[allSlots, timezone],
	)

	const intl = useIntl()

	if (isAvailabilityLoading) return <TimeSlotSectionLoading />

	return (
		<Flex w="full" h="full" justifyContent="space-evenly" gap="24px">
			<Zone
				slots={morningSlots}
				name={intl.formatMessage({ id: SchedulerTranslationsTokens.morning })}
			/>
			<Zone
				slots={afternoonSlots}
				name={intl.formatMessage({ id: SchedulerTranslationsTokens.daytime })}
			/>
			<Zone
				slots={eveningSlots}
				name={intl.formatMessage({ id: SchedulerTranslationsTokens.evening })}
			/>
		</Flex>
	)
}

export default TimePicker

type ZoneProps = {
	slots: string[]
	name: string
}

const Zone = ({ slots, name }: ZoneProps) => {
	const { styles } = useCalendarContext()
	if (slots.length === 0) return null
	return (
		<Flex w="full" direction="column" gap="8px" pos="relative">
			<Flex
				direction="column"
				gap="8px"
				w="full"
				minH="40vh"
				maxH="40vh"
				overflowY="scroll"
				className="hide_scrollbar"
				pb="125px"
			>
				<Flex
					w="full"
					minH="38px"
					h="38px"
					mt="8px"
					bg={styles.is_translucent ? 'translucent' : styles.bg_primary}
					zIndex={20}
					alignItems="center"
				>
					<Text
						color={styles.text_tertiary}
						fontSize="13px"
						fontWeight={600}
						mt="-8px"
					>
						{name}
					</Text>
				</Flex>

				{slots?.map((s, i) => {
					return <Slot key={s} slot={s} i={i} />
				})}

				{!styles.is_translucent && (
					<Flex
						pos="absolute"
						bottom="0"
						w="full"
						h="24px"
						zIndex={99}
						bgImage={`linear-gradient(to bottom, ${styles.bg_primary}00 0%, ${styles.bg_primary} 100%);`}
					/>
				)}
			</Flex>
		</Flex>
	)
}

type SlotProps = {
	slot: string
	i: number
}

const Slot: React.FC<SlotProps> = React.memo(({ slot, i }) => {
	const id = slot
	const {
		webinarSlots,
		maxWebinarAttendees,
		timezone,
		styles,
		isScheduling,
		handleScheduleMeeting,
		timeslot,
		setTimeslot,
		locale,
		errorSlots,
		isWebinarEnabled,
	} = useCalendarContext()

	const isErrored = errorSlots?.includes(slot)

	const clicked = id === timeslot

	const [loadingButton, setLoadingButton] = React.useState('')
	const [zIndex, setZIndex] = React.useState(1)

	const intl = useIntl()

	const dateWithTimezoneLocale = moment(slot).tz(timezone).locale(locale)
	const selectedWebinarSlot = webinarSlots?.find((ws) => {
		return slot.toString() === ws.timeSlot.toString()
	})

	const formattedTime = dateWithTimezoneLocale.format('h:mm A').toString()

	const isLoading = clicked && loadingButton === id && isScheduling

	const spotsNumber =
		selectedWebinarSlot?.available_slots || maxWebinarAttendees

	useEffect(() => {
		if (!clicked) {
			setTimeout(() => {
				setZIndex(1)
			}, 201)
			return
		}
		setZIndex(20)
	}, [clicked])

	const handleOnClick = () => {
		if (isScheduling) return
		if (clicked && handleScheduleMeeting) {
			handleScheduleMeeting(slot, selectedWebinarSlot?.memberId)
			setLoadingButton(id)
		} else {
			setTimeslot(id)
		}
	}

	useEffect(() => {
		if (isErrored) {
			setTimeslot('')
		}
	}, [isErrored])

	return (
		<Flex
			flexDirection="column"
			position="relative"
			zIndex={zIndex}
			mt={i === 0 ? '-8px' : 0}
		>
			<Flex
				pos="absolute"
				bg={styles.btn_bg_tertiary}
				color="#fff"
				w="full"
				h={clicked ? '46px' : '34px'}
				borderTopLeftRadius="6px"
				borderTopRightRadius="6px"
				mt={clicked ? '-40px' : '2px'}
				transition="margin-top 200ms ease, height 200ms ease"
				opacity={!clicked && styles.is_translucent ? 0 : 1}
				flexWrap="wrap"
				alignItems="center"
				justifyContent="center"
				overflow="hidden"
				alignContent="center"
				pb={1}
			>
				<Text fontSize="12px" fontWeight={600} noOfLines={1} mr="2px">
					{dateWithTimezoneLocale.format('MMM D h:mm A')}
				</Text>
				<Text fontSize="10px" fontWeight={500} noOfLines={1}>
					({`${timezone}`})
				</Text>
			</Flex>
			<Button
				key={slot}
				onClick={(e) => {
					e.stopPropagation()
					handleOnClick()
				}}
				zIndex={10}
				w="full"
				h={spotsNumber !== undefined ? '50px' : '40px'}
				minH="40px"
				rounded="6px"
				border="1px solid"
				borderTop={clicked ? 'none' : '1px solid'}
				borderColor={clicked ? 'transparent' : styles.border_primary}
				color={
					clicked || isLoading ? styles.btn_color_primary : styles.text_primary
				}
				boxShadow="none"
				isLoading={isLoading}
				_disabled={{
					opacity: 1,
					cursor: 'not-allowed',
				}}
				bg={
					clicked || isLoading
						? styles.btn_bg_primary + '!important'
						: styles.bg_secondary
				}
				_hover={{
					bg: clicked ? styles.btn_bg_primary : styles.bg_secondary,
					borderColor: clicked ? 'transparent' : styles.hover_border_primary,
					_disabled: {
						bg: styles.bg_secondary,
						borderColor: styles.border_primary,
					},
				}}
				isDisabled={isErrored}
				flexDir="column"
				gap="4px"
			>
				<Text
					fontSize="14px"
					sx={{
						[CALENDER_QUERIES.xs]: {
							fontSize: '12px',
						},
					}}
					fontWeight={600}
					textDecoration={isErrored ? 'line-through' : 'none'}
				>
					{clicked
						? intl.formatMessage({ id: SchedulerTranslationsTokens.confirm })
						: formattedTime}
				</Text>
				{spotsNumber !== undefined && isWebinarEnabled && (
					<Text fontSize="12px" fontWeight={500} noOfLines={1}>
						{spotsNumber} spot
						{spotsNumber === 1 ? '' : 's'} left
					</Text>
				)}
			</Button>
		</Flex>
	)
})

Slot.displayName = 'Slot'
